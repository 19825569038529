import CoordTransform from '../../Util/Components/CoordTransform.js';

// ---------------------------- OpenLayers libs --------------------------------
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {Circle, Fill, Icon, Stroke, Style} from 'ol/style';
import {Polygon, LinearRing} from "ol/geom";
import GeoJSON from "ol/format/GeoJSON";
import {getCenter} from "ol/extent";

// ------------------------ End of OpenLayers libs -----------------------------

class SDMSPHOTOLayerOL {
    constructor(mapOL, options) {
        options = options ? options : {};
        this._epsg = options.epsg ? options.epsg : '3857';

        // Directly init the vector layer to work on
        /// Pay attention to whether the layer ID could be registered after added to map
        this._mapOL = mapOL ? mapOL : window.mapOL;
        this._vectorSource = new VectorSource({wrapX: false});
        let vectorLayer = new VectorLayer({
            minZoom: 15,
            source: this._vectorSource
        });
        vectorLayer.setZIndex(1e4);     // 1e4 or something else

        this._id = options.id ? options.id : 0;
        this._imgSrc =  './camera-billboard@2x.png'
        vectorLayer.id = this._id
        vectorLayer.name = this._id+"_photos"
        this._layer = vectorLayer;
        console.log('add vector layer')
        this._mapOL.addLayer(vectorLayer);

        this.style = new Style({
            image: new Circle({
                radius: 1,
                fill: new Fill({color: 'transparent'}),
                stroke: new Stroke({
                    color: [0,255,0], width: 2
                })
            })
        })

        this.style1 = new Style({
            image: new Icon({
                anchor:[0.5,0.5],
                src: this._imgSrc, // still need something even if it's invisible
                opacity: 1,
                scale: options.scale ? options.scale : 1    // How to improve the scale issue? Since the raw image may not have fixed pixel size
            })
        });

        this.style2 = new Style({
            stroke: new Stroke({
                color: 'red',
                width: 3,
            }),
            fill: new Fill({
                color: 'rgba(255, 0, 0,1)',
            }),
        });
    }

    addPoints(pts, geometry) {
        for (let i = 0; i < pts.length; i++) {
            let pt = pts[i]

            const obj = JSON.parse(geometry);
            var boundary = new GeoJSON().readFeature(obj)
            var center = getCenter(boundary.getGeometry().getExtent())
            let hkCoord = CoordTransform.translate({
                fromEPSG: '4326',
                toEPSG: this._epsg,
                x:  pt.longitude ,
                y:  pt.latitude
            });

            let hkCoord1 = null
            if (this._epsg !== '2326') {
                hkCoord1 = CoordTransform.translate({
                    fromEPSG: '4326',
                    toEPSG: '2326',
                    x: pt.longitude,
                    y: pt.latitude
                });
            }


            let ptFeature = new Feature({
                geometry: new Point([
                    hkCoord[0],
                    hkCoord[1]
                ]),
                name: pt.title
            });
            const style = pt.type === 'photo' ? this.style : this.style1;
            ptFeature.setStyle(style);
            ptFeature.setProperties({type: pt.type})
            ptFeature.setProperties({url: pt.url})
            ptFeature.setProperties({mediaId: pt.mediaId})
            this._vectorSource.addFeature(ptFeature);

            if (pt.type === 'photo') {
                let circle = this.createRegularPolygonCurve([
                    hkCoord[0],
                    hkCoord[1]
                ], 7, 100, 60, pt.kappa)

                var circleFeature = new Feature({
                    geometry: circle,
                    name: pt.title + "_circle"
                });
                circleFeature.setStyle(this.style2)
                circleFeature.setProperties({type: pt.type})
                circleFeature.setProperties({url: pt.url})
                circleFeature.setProperties({mediaId: pt.mediaId})
                this._vectorSource.addFeature(circleFeature);
            }
        }
    }

    getExtent() {
        return this._vectorSource.getExtent()
    }

    /**
     * APIMethod:OpenLayers绘制扇形的接口扩展
     * @param origin 圆心
     * @param radius 半径
     * @param sides 边数
     * @param r 弧度
     * @param angel 旋转角度（扇形右边半径与x正向轴的角度）
     * @returns {OpenLayers.Geometry.Polygon}
     */
    createRegularPolygonCurve(origin, radius, sides, r, angel) {
        let rotation = 360 - r;
        let angle = Math.PI * ((1 / sides) - (1 / 2));
        if (rotation) {
            angle += (rotation / 180) * Math.PI;
        }
        let rotatedAngle, x, y;
        let points = [];
        for (let i = 0; i < sides; ++i) {
            let an = i * ((360 - rotation) / 360);
            rotatedAngle = angle + (an * 2 * Math.PI / sides);
            x = origin[0] + (radius * Math.cos(rotatedAngle));
            y = origin[1] + (radius * Math.sin(rotatedAngle));
            points.push([x, y]);
        }
        if (rotation != 0) {
            points.push(origin);
        }
        var ring = new LinearRing(points);
        ring.rotate((180+r/2-angel) / 57.3, origin);
        let list = ring.getCoordinates()

        return new Polygon([list]);
    }

}

export default SDMSPHOTOLayerOL;
