import SDMSPHOTOLayerOL from "@/libs/VR3D/OpenLayers/Layers/SDMSPHOTOLayerOL";

const proj4 = require('proj4').default;

import Service from './service.js';
import Cookies from "js-cookie";
import CoordTransform from './VR3D/Util/Components/CoordTransform.js';
import {
    register as proj4Register
} from 'ol/proj/proj4';
import {
    get as projGet,
    transform as projTransform
} from 'ol/proj';
import Util from './VR3D/Util/Util.js';

import Point from 'ol/geom/Point';
import KML from 'ol/format/KML';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import {TileImage,ImageArcGISRest} from 'ol/source';
import {getCenter} from "ol/extent";
import XYZ from 'ol/source/XYZ';
import Overlay from "ol/Overlay";
import TileGrid from 'ol/tilegrid/TileGrid';
import ScaleLine from 'ol/control/ScaleLine';
import {
    defaults as interactionDefaults
} from 'ol/interaction';
import View from 'ol/View';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import {
    Fill,
    Icon,
    Stroke,
    Style,
    Circle,
    Text
} from 'ol/style';

// ------------------------ End of OpenLayers libs -----------------------------

window.viewer = undefined;

class MapOL {
    constructor(containerID) {
        this._containerID = containerID;

        // Specified for HK1980 Grid Coordinate System.
        /// But what about other projs?
        this._hkProjection = undefined;
        this._hkTileGrid = undefined;

        // Basemap list
        this._selectedBaseMap = -1;
        this.baseMapType = [];
        this.baseMapOptions = [];
        this.baseMap2DUrls = [];
        this.baseMapSets = [];
        this.baseMap2DQueryDatasets = [];
        /// TODO: label configs

        // Drawing list (to be indexed by drawingId)
        this._drawings = {};

        // View sync features
        this._viewVecSource = undefined;
        this._panoFan = undefined;
        this._3dViewBound = undefined;

        // POI features
        this._poiVecSource = undefined;
        this.selectedPhoto = null
    }

    initMap(options) {
        var that = this
        /// What should the 'options' be. And check PDIP for the default view position.
        if (!options) {
            options = {
                latLng: [
                    114.13926936344937,
                    22.352558697655706
                ],
                zoom: 11.5,
                baseMapIdx: 103,
                layers: []
            };
        }

        const container = document.getElementById('popup');

        const closer = document.getElementById('popup-closer');
        const overlay = new Overlay({
            element: container,
            autoPan: {
                animation: {
                    duration: 250,
                },
            },
        });

        closer.onclick = function () {
            overlay.setPosition(undefined);
            closer.blur();
            return false;
        };


        window.mapOL = new Map({
            target: this._containerID,
            interactions: interactionDefaults({doubleClickZoom: false}),
            layers: [],
            overlays: [overlay],
            /// Should this be WGS84 or mercator by default?
            view: new View({
                zoom: options.zoom,
                center: projTransform([options.latLng[0], options.latLng[1]], 'EPSG:4326', 'EPSG:3857'),
                multiWorld: true,
                maxZoom: 21,
                minZoom: 10
            })
        });

        // window.mapOL.getView().setMaxZoom(19);
        // window.mapOL.getView().setMinZoom(10);
        // Disable right click menu
        let mapDom = document.getElementById(this._containerID);
        mapDom.oncontextmenu = function (e) {
            return false;
        };

        let scaleControl = new ScaleLine();
        window.mapOL.addControl(scaleControl);


        const tempLayer = new VectorLayer()



        window.mapOL.on('singleclick', function (evt) {
            console.log('single');

            var feature = window.mapOL.forEachFeatureAtPixel(evt.pixel,
                function (feature, layer) {
                    console.log(feature, layer)
                    if (feature.getProperties().type === 'photo') {

                        window.mapOL.getLayers().forEach(layer => {
                            if (layer && layer.name === 'seletedphotos') {
                                window.mapOL.removeLayer(layer);
                            }
                        });

                        var copyFeature = feature.clone()
                        var copyStyle = feature.getStyle().clone()
                        copyStyle.getFill().setColor("#00a6ff")

                        var tempSource = new VectorSource({
                            features:[copyFeature]
                        })
                       tempLayer.setSource(tempSource);
                        tempLayer.abc = Math.random();
                        tempLayer.name = 'seletedphotos'
                        copyFeature.setStyle(copyStyle)
                        tempLayer.setZIndex(10001)
                        window.mapOL.addLayer(tempLayer)
                        that.selectedPhoto = feature
                        Window.vui.getSelectedPhotos(feature.getProperties().mediaId)
                    } else if (feature.getGeometry().getType() === 'Point' && feature.getProperties().type === 'video') {
                        that.selectedPhoto = feature
                        Window.vui.getSelectedVideos(feature.getProperties().mediaId)
                        overlay.setPosition(evt.coordinate);
                    }
                });
        });


        window.mapOL.on('dblclick', function (evt) {
            window.mapOL.getLayers().forEach(layer => {
                if (layer && layer.name === 'seletedphotos') {
                    window.mapOL.removeLayer(layer);
                }
            });
            console.log('doubled');
            var feature = window.mapOL.forEachFeatureAtPixel(evt.pixel,
                function (feature, layer) {
                    console.log(feature, layer)
                    if (feature.getProperties().type === 'footprint') {
                        Window.vui.zoomToLayer(feature.getProperties().layerId)
                    }
                });
        });

        window.mapOL.on('pointermove', e => {
            let pixel = window.mapOL.getEventPixel(e.originalEvent);
            let hit = window.mapOL.hasFeatureAtPixel(pixel, {
                layerFilter: layer => {
                    if (
                        layer.name && layer.name.endsWith('photos')
                    ) {
                        return true;
                    }
                },
            });
            window.mapOL.getTargetElement().style.cursor = hit ? 'pointer' : '';
        });

        this.setBaseMap(options.baseMapIdx);

        this.layerCtrl(options.layers);
    }

    zoomTo(zoomInfo, mapOL) {
        mapOL = mapOL ? mapOL : window.mapOL;

        let dX = 0, dY = 0;
        if (zoomInfo.height && !zoomInfo.zoom) {
            // 3D height transform to zoom (Roughly. Something might be figured out for WebMercator)
            let mapDom = document.getElementById(this._containerID);
            let mapDomWidth = mapDom.offsetWidth;   // In pixel

            let heading = zoomInfo.heading ? zoomInfo.heading : 0;
            // If pitch is not looking downwards, do not try to deal with this and by default adjust to downward view
            let pitch = (zoomInfo.pitch && zoomInfo.pitch < 0) ? zoomInfo.pitch : -Math.PI / 2;

            // Adjust view center instead of using the camera center
            let rawHeight = Math.max(zoomInfo.height, 1);
            let adjustHeight = rawHeight / Math.sin(-pitch);    // 'adjustHeight' stands for the distance between camera center and view center
            dX = adjustHeight * Math.cos(pitch) * Math.sin(heading);
            dY = adjustHeight * Math.cos(pitch) * Math.cos(heading);

            // let viewWidth = 2 * zoomInfo.height / Math.sqrt(3);   // In meter
            let viewWidth = 2 * adjustHeight / Math.sqrt(3);   // In meter
            let tileWidth = viewWidth / (mapDomWidth / 256);

            const EARTH_RADIUS = 6378137;
            const EARTH_PERIMETER = 2 * Math.PI * EARTH_RADIUS;

            let zoomLvl = Math.log(EARTH_PERIMETER / tileWidth) / Math.log(2);
            zoomInfo.zoom = zoomLvl;
        }

        let wmCoords = CoordTransform.translate({
            fromEPSG: '4326',
            toEPSG: '3857',
            x: zoomInfo.longitude,
            y: zoomInfo.latitude
        });

        wmCoords[0] += dX;
        wmCoords[1] += dY;

        if (zoomInfo.zoom > 19.184869865259344) zoomInfo.zoom = 19.184869865259344

        mapOL.setView(new View({
            center: wmCoords,
            zoom: zoomInfo.zoom,
            multiWorld: true
        }));

        Window.vui.homeZoomLevel = zoomInfo.zoom;
    }

    zoomToGeometry(geometry, mapOL) {
        mapOL = mapOL ? mapOL : window.mapOL;
        const obj = JSON.parse(geometry);
        var boundary = new GeoJSON().readFeature(obj)
        var extent = boundary.getGeometry().transform('EPSG:4326', 'EPSG:3857').getExtent()
        mapOL.getView().fit(extent)
    }

    regBasemap(basemapList) {
        let that = this;

        // Init HK1980 coordinate system
        if (!this._hkProjection || !this._hkTileGrid) {
            proj4.defs('EPSG:2326', '+proj=tmerc +lat_0=22.31213333333334 +lon_0=114.1785555555556 +k=1 +x_0=836694.05 +y_0=819069.8 +ellps=intl +towgs84=-162.619,-276.959,-161.764,0.067753,-2.24365,-1.15883,-1.09425 +units=m +no_defs');
            proj4Register(proj4);



            this._hkProjection = projGet('EPSG:2326');

            var extent = [760149.2252984506, 802219.3294386589,909850.7747015494, 887785.7505715012];
            var hkorigin = [795000, 855000];

            var resolutions = [158.75031750063502, 85.98975531284397,
                52.91677250021167,  26.458386250105836,
                13.229193125052918, 5.291677250021167,
                1.9843789687579376, 1.3229193125052918,
                0.6614596562526459, 0.5291677250021167,
                0.26458386250105836, 0.13229193125052918];

            var scales = [600000,325000,
                200000,100000,
                50000, 20000,
                7500, 5000,
                2500, 2000,
                1000, 500];

            this._hkTileGrid = new TileGrid({
                extent: extent,
                origin: hkorigin,
                scales: scales,
                resolutions: resolutions,
                tileSize: 256
            });
        }

        function regGenericParams(basemapItem) {
            let sourceURL = basemapItem.url2d ? basemapItem.url2d : basemapItem.url;

            that.baseMapSets[basemapItem.layerId] = basemapItem.title;
            that.baseMap2DUrls[basemapItem.layerId] = sourceURL;
        }

        // Register one by one, but only when it is a supported 2D layer
        for (let i = 0; i < basemapList.length; i++) {
            let basemapItem = basemapList[i];
            let sourceURL = basemapItem.url2d ? basemapItem.url2d : basemapItem.url;

            switch (basemapItem.type) {
                case 'TEMPLATE':
                    this.baseMapType[basemapItem.layerId] = XYZ;
                    /// Missing: whether the map is in WGS84 or HK1980
                    this.baseMapOptions[basemapItem.layerId] = {
                        url: sourceURL.replace('{s}', '{a-c}'),
                        projection: basemapItem.layerId === 105 ? this._hkProjection:projGet('EPSG:3857'),
                        crossOrigin: 'Anonymous',
                    };
                    if(basemapItem.layerId === 105){
                        console.log(this._hkProjection)
                        this.baseMapOptions[basemapItem.layerId].tileGrid = this._hkTileGrid
                    }
                    console.log(this.baseMapOptions)
                    regGenericParams(basemapItem);
                    break;

                // Add here if any other map type for base map
            }

            /// To add: queryable; labelling
        }
    }

    setBaseMap(v) {
        if (v != this._selectedBaseMap) {
            this._selectedBaseMap = v;

            // Remove current basemap (and labelling)
            /// Labelling not yet implemented
            let olLayers = window.mapOL.getLayers().getArray();
            for(let i=olLayers.length-1; i>=0; i--) {
              if(olLayers[i].isBaseLayer) {
                olLayers[i].dispose();
                window.mapOL.removeLayer(olLayers[i]);
              }
            }
            let zindex = -3;

            if (v != -1 && this.baseMapType[v]) {
                let baseLayer = new TileLayer({
                    source: new this.baseMapType[v](this.baseMapOptions[v]),
                    maxZoom: 21
                });
                baseLayer.name = this.baseMapSets[v];
                baseLayer.isBaseLayer = true;
                if (Window.vui.baseLayer2DItems && Window.vui.baseLayer2DItems[v] && Window.vui.baseLayer2DItems[v].isOnly)
                    zindex = -3;
                else
                    zindex = -2;
                baseLayer.setZIndex(zindex);
                console.warn(baseLayer)
                window.mapOL.addLayer(baseLayer);
            }

            if (this.baseMapSets[v].startsWith('LandsD')) {
                let langg = Window.vui.$i18n.locale == 'en-US' ? 'en' : (Window.vui.$i18n.locale == 'zh-TW' ? 'tc' : 'sc');
                let labelsource = {
                    url: 'https://mapapi.geodata.gov.hk/gs/api/v1.0.0/xyz/label/hk/' + langg + '/WGS84/{z}/{x}/{y}.png',
                    crossOrigin: "Anonymous"
                };
                let labelLayer = new TileLayer({
                    source: new this.baseMapType[v](labelsource),
                    maxZoom: 21
                });
                labelLayer.name = 'landsD_basemap_label';
                labelLayer.isBaseLayer = true;
                labelLayer.setZIndex(zindex);
                window.mapOL.addLayer(labelLayer);
            } else {
                let labelsource = {
                    url: 'https://pld-rcpdhweb/arcgis/rest/services/Basemap/OZP_BMS_LABEL_ENG/MapServer',
                    projection: this._hkProjection,
                    crossOrigin: "Anonymous",
                    // tileGrid:this._hkTileGrid
                };
                let labelLayer = new ImageLayer({
                    source: new ImageArcGISRest(labelsource),
                    // maxZoom: 19
                });
                labelLayer.name = 'OZP_Label';
                labelLayer.isBaseLayer = true;
                labelLayer.setZIndex(zindex);
                window.mapOL.addLayer(labelLayer);
            }
        }
    }

    changeBasemapLabelLang(l) {
        let olLayers = window.mapOL.getLayers().getArray();
        let found = olLayers.find(i => i.name == 'landsD_basemap_label');
        if (found) window.mapOL.removeLayer(found);

        let langg = Window.vui.$i18n.locale == 'en-US' ? 'en' : (Window.vui.$i18n.locale == 'zh-TW' ? 'tc' : 'sc');
        let labelsource = {
            url: 'https://mapapi.geodata.gov.hk/gs/api/v1.0.0/xyz/label/hk/' + langg + '/WGS84/{z}/{x}/{y}.png',
            crossOrigin: "Anonymous"
        };
        let labelLayer = new TileLayer({
            source: new TileImage(labelsource)
        });
        labelLayer.name = 'landsD_basemap_label';
        labelLayer.isBaseLayer = true;
        labelLayer.setZIndex(-2);
        window.mapOL.addLayer(labelLayer);
    }

    layerCtrl(layerList, mapOL, options) {
        mapOL = mapOL ? mapOL : window.mapOL;
        // List of layers to open
        let layerIdsToOpen = [];
        for (let i = 0; i < layerList.length; i++) {
            let layerItem = layerList[i];
            let layerId = layerItem.k;
            layerIdsToOpen.push(layerId);
        }

        // Remove layers that are neither basemap nor in the list of layers to be opened
        let olLayers = mapOL.getLayers().getArray();
        let layerIdsExisting = [];
        for (let i = olLayers.length - 1; i >= 0; i--) {
            let olLayer = olLayers[i];
            if ( layerIdsToOpen.indexOf(olLayer.id) == -1 && !olLayer.isBaseLayer && !olLayer.isForDrawings) {
                // Remove the layer
                olLayers[i].dispose();
                mapOL.removeLayer(olLayers[i]);
            } else if (olLayer.id && layerIdsToOpen.indexOf(olLayer.id) != -1) {
                layerIdsExisting.push(olLayer.id);
            }

        }

        // Add layers one by one, if the layer has not been opened
        for (let i = 0; i < layerList.length; i++) {
            let layerItem = layerList[i];
            let layerId = layerItem.k;

            let layerUrl = layerItem.url2d ? layerItem.url2d : layerItem.url;

            let caseObj = layerItem.caseObj;
            if (!caseObj) {
                try {
                    caseObj = JSON.parse(layerItem.case);
                } catch (e) {
                    caseObj = {};
                }
            }
            if (!caseObj)
                caseObj = {};

            if (layerIdsExisting.indexOf(layerId) != -1) {
                // Layer already opened. Skip
                continue;
            } else {
                // CORE: Add the layer according to the layer type
                let mapLayer;
                let imgLayer = null;
                let that = this;
                switch (layerItem.type) {
                    case 'SM_MAP':
                        layerUrl = layerUrl.replace(/&amp;/g, '&');
                        mapLayer = new TileLayer({
                            source: new TileSuperMapRest({
                                url: window.Baseurl + layerUrl,
                                wrapX: true,
                                tileGrid: caseObj.isWMIn2D ? undefined : this._hkTileGrid,
                                crossOrigin: 'Anonymous',
                                tileLoadFunction: function (tile, src) {
                                    tile.getImage().src = src
                                }
                            }),
                            projection: 'EPSG:3857',
                            // projection: caseObj.isWMIn2D ? undefined : this._hkProjection,
                            // tileGrid: caseObj.isWMIn2D ? undefined : this._hkTileGrid
                        });

                        mapLayer.queryDataset = layerItem.queryDataset;
                        break;

                    case 'TEMPLATE':
                        let maxZoom, minZoom;
                        if (caseObj) {
                            if (caseObj.maximumLevel)
                                maxZoom = caseObj.maximumLevel;
                            if (caseObj.minimumLevel)
                                minZoom = caseObj.minimumLevel;
                        }

                        layerUrl = layerUrl.replace(/&amp;/g, '&');
                        let fullUrl = window.baseHost + layerUrl;
                        if (layerUrl.indexOf('?') == -1) {
                            fullUrl += '?token=' + Util.getToken();
                        }

                        mapLayer = new TileLayer({
                            source: new XYZ({
                                // url: window.Baseurl + layerUrl + '?token='+Util.getToken(),
                                url: fullUrl,
                                crossOrigin: 'Anonymous',
                                tileLoadFunction: function (tile, src) {
                                    tile.getImage().src = src
                                }
                            }),
                            maxZoom: maxZoom,
                            minZoom: minZoom
                        });
                        mapLayer.queryDataset = layerItem.queryDataset;
                        break;

                    case 'GEOJSON':
                        let xmlHttp = new XMLHttpRequest();
                        xmlHttp.onreadystatechange = function () {
                            if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
                                let dt = JSON.parse(xmlHttp.responseText);

                                let vecLayer = new VectorLayer({
                                    source: new VectorSource({
                                        features: new GeoJSON({
                                            dataProjection: 'EPSG:4326',
                                            featureProjection: window.map._hkProjection
                                        }).readFeatures(dt)
                                    })
                                });
                                vecLayer.id = layerId;
                                mapOL.addLayer(vecLayer);
                            } else if (xmlHttp.readyState == 4 && xmlHttp.status > 399) {
                                console.error(xmlHttp.responseText);
                            }
                        }
                        xmlHttp.open('GET', `${Baseurl}${layerUrl}`, true); // true for asynchronous
                        if (Cookies.get("sdms_token")) {
                            xmlHttp.setRequestHeader(
                                "token",
                                Cookies.get("sdms_token")
                            );
                        } else if (Cookies.get("workspace_token")) {
                            xmlHttp.setRequestHeader("token", Cookies.get("workspace_token"));
                        }
                        xmlHttp.setRequestHeader(
                            "accept-language",
                            window.localStorage.language
                        );
                        /* xmlHttp.setRequestHeader(
                          "Content-Type",
                          "multipart/form-data; charset=UTF-8"
                          ); */
                        xmlHttp.withCredentials = true;
                        xmlHttp.send();
                        break;

                    case 'PHOTO':
                        Service.request(
                            "post",
                            "/sys/media/layer/list", {
                                layerId: layerItem.k
                            },
                            function (dt) {
                                console.log('add photo to 2d layer')
                                console.log(dt)
                                let photoLayer = new SDMSPHOTOLayerOL(mapOL, {
                                    id: layerItem.k,
                                    scale: 0.5
                                });
                                photoLayer.addPoints(dt.data, layerItem.geometry);
                            })
                        break;

                    case 'KML':
                        var styles = new Style({
                            fill: new Fill({
                                color: 'rgba(255,255,255, 0)'
                            }),
                            stroke: new Stroke({
                                color: '#ff0000',
                                width: 2
                            })
                        });
                        var vectorSource2 = new VectorSource({
                            url: window.baseHost + '/sdms/doc/kml/' + layerItem.url + '?token=' + Util.getToken(),
                            format: new KML({
                                dataProjection: 'EPSG:4326',
                                featureProjection: 'EPSG:3857',
                                extractStyles: false
                            }),
                            projection: 'EPSG:3857'
                        });
                        var kmlLayer = new VectorLayer({
                            minZoom: 15,
                            source: vectorSource2,
                            style: styles
                        });
                        kmlLayer.name = layerId + '_trajectory'
                        mapLayer = kmlLayer
                        break;

                    default:
                        break;
                }

                if (mapLayer) {
                    mapLayer.id = layerId;
                    mapOL.addLayer(mapLayer);
                }
            }
        }
    }

    clearAll() {
        for (var i = 0; i < window.measures.length; i++) {
            window.measures[i].clear();
        }
        window.measures = [];

        for (var i = 0; i < window.analysis.length; i++) {
            window.analysis[i].clear();
        }
        window.analysis = [];

        // if(window.integratedObj.sketchProject){
        // window.integratedObj.sketchProject.clear();
        // }


        // Mind some special cases that is defined as global parameter
        this.shadowController = undefined;

        for (var p in this.functionEntityGroups) {
            this.functionEntityGroups[p].clear(window.viewer);
            this.functionEntityGroups[p] = new EntityObjCollection();
        }

        if (window.poiMarker) {
            window.poiMarker.clear();
            window.poiMarker = undefined;
        }

        if (window.integratedObj && window.integratedObj.panoViewer)
            window.integratedObj.panoViewer.clearAnalysis();

        // This should be from the routing module in PDIP. We don't have this in vr3d.cloud viewer, so skip this
        /*
        for (let i = 0; i < this._navigationEntities.length; i++) {
          let navEntity = this._navigationEntities[i];
          window.viewer.entities.remove(navEntity);
        }
        this._navigationEntities = [];
         */

        // Unlink the bindings to any 'currently active analysis objects'
        window.analysisObjects = {
            flood: undefined,
            shadow: undefined,
            flypath: undefined,
            volume: undefined,
            profile: undefined,
            clip_plane: undefined,
            skyline: undefined,
            skyview: undefined,
            clip_box: undefined,
            terrain_modify: undefined,
            volumeMultiple: undefined,
            pipe3D: undefined
        };
    }

    contextmenu(e) {
        e.preventDefault();
        let vui = Window.vui;
        let mapOL = window.mapOL;

        vui.identifyRuning = false
        console.log('contextmenu', e);

        mapOL.getViewport().style.cursor = '';
        mapOL.un('click', map2D.identify2DFunction);
    }

    identify2DFunction(e) {
        let that = this
        let mapOL = window.mapOL;
        let viewer = window.viewer;

        let vui = Window.vui;

        mapOL.getViewport().style.cursor = '';
        mapOL.un('click', this);
        e.preventDefault();

        // select
        const selected = [];
        const highlightStyle = new Style({
            fill: new Fill({
                color: '#EEE',
            }),
            stroke: new Stroke({
                color: '#3399CC',
                width: 2,
            }),
        });

        let features2D = mapOL.getFeaturesAtPixel(e.pixel);
        features2D


        // console.log(e, e.coordinate);
        e.position = {x: e.pixel[0], y: e.pixel[1]}
        let clickCoord = e.coordinate;

        // // based on 2d
        // // make Identify Request for supermap map service
        // let smIndentifyPromise = that.makeIdentifyRequest(clickCoord)
        // smIndentifyPromise.then(function(result) {

        //   let isShp = false;
        //   if (result.isShp) {
        //     delete(result.isShp);
        //     isShp = true;
        //   }

        //   // let res = {
        //   //   bbdy:[]
        //   // }
        //   // for (const property in result) {
        //   //   if (result[property].length >0) {
        //   //     res.bbdy = res.bbdy.concat(result[property])
        //   //   }
        //   // }
        //   // console.log(res);
        //   URAVue.$emit("on_add_anno", {
        //     position: e,
        //     entity: result,
        //     isSHP: isShp
        //   });
        // });

        // below funciton rely on the 3d Indentification
        // let clickCoord = mapOL.getCoordinateFromPixel(e.pixel);
        let pxResolution = 5 * mapOL.getView().getResolution();

        let rings = [[
            [clickCoord[0] - pxResolution, clickCoord[1] - pxResolution],
            [clickCoord[0] + pxResolution, clickCoord[1] - pxResolution],
            [clickCoord[0] + pxResolution, clickCoord[1] + pxResolution],
            [clickCoord[0] - pxResolution, clickCoord[1] + pxResolution],
            [clickCoord[0] - pxResolution, clickCoord[1] - pxResolution]
        ]]
        let ringsWGS84 = [[]]

        rings[0].forEach((coord) => {
            let coordWGS84 = Util.CoordTransform.translate({
                x: coord[0],
                y: coord[1],
                fromEPSG: '3857',
                toEPSG: '4326'
            });
            ringsWGS84[0].push(coordWGS84)
        })

        let geojson = {
            "rings": ringsWGS84,
            "spatialReference": {
                "wkid": 4326,
                "latestWkid": 4326
            }
        };

        console.log("geojson 2d", geojson, rings)
        let identifyPromise = vui.Map.identify(geojson, viewer);
        identifyPromise.then(function (result) {

            let isShp = false;
            if (result.isShp) {
                delete (result.isShp);
                isShp = true;
            }

            if (Object.keys(result).length == 0)
                return;

            vui.Map.selectedFeature = {
                c: new Cesium.Cartographic(ringsWGS84[0][0] * Math.PI / 180, ringsWGS84[0][1] * Math.PI / 180, 0),
                position: e,
                entity: result
            };

            URAVue.$emit("on_add_anno", {
                position: e,
                entity: result,
                isSHP: isShp
            });
            viewer.identifyEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
            window.viewer.scene.canvas.style.cursor = '';
            if (window.viewer_split) {
                window.viewer_split.identifyEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
                window.viewer_split.scene.canvas.style.cursor = '';
            }

            var s3mLayers = viewer.scene.layers._layers._array;
            for (var i = 0; i < s3mLayers.length; i++) {
                s3mLayers[i].selectEnabled = false;
            }

            viewer.scene.requestRender();
        });
    }

    identify(geometry, all = false) {
        console.log("geometry", geometry)
        var that = this;

        // debugger;
        function smRawGeomToGeoJSONs(geomRaw, isToWGS84) {
            // console.log(geomRaw);

            var geomRawParts = geomRaw.parts;
            var geomRawPartTopo = geomRaw.partTopo;
            var geomRawPoints = geomRaw.points;

            var geomList = [];
            if (geomRaw.type == 'REGION') {
                let geomType = 'Polygon';

                let currentPartVertexId = 0;
                for (let h = 0; h < geomRawPartTopo.length; h++) {
                    let topoId = geomRawPartTopo[h];
                    if (topoId == 1) {
                        geomList.push({
                            "type": geomType,
                            "coordinates": []
                        });
                    }

                    let partIdx = geomRawParts[h];
                    let coords = [];

                    for (let j = currentPartVertexId; j < currentPartVertexId + partIdx; j++) {
                        let pt = geomRawPoints[j];

                        if (isToWGS84) {
                            if (pt.x > 180 || pt.x < -180) {
                                // console.log("transform[pt.x, pt.y]",[pt.x, pt.y])
                                coords.push(ol.proj.transform([pt.x, pt.y], 'EPSG:2326', 'EPSG:4326'));
                            }
                            // coords.push(ol.proj.transform( [pt.x, pt.y], 'EPSG:2326', 'EPSG:4326'));
                        } else {
                            coords.push([pt.x, pt.y]);
                        }
                    }

                    geomList[geomList.length - 1].coordinates.push(coords);

                    currentPartVertexId += partIdx;
                }
            }

            // console.log(geomList);
            return geomList;
        }

        return new Promise(function (resolve, reject) {
            if (window.mapOL && Window.vui.is2D) {
                console.log("enter mapol")
                /// Means that the identification is taking place in 2D
                let mapOLLayers = mapOL.getLayers().getArray();

                let queryDatasetCache = [];

                function getDuplicateDataService(queryDataset) {
                    for (let i = 0; i < queryDatasetCache.length; i++) {
                        let existingQueryDataset = queryDatasetCache[i];
                        if (
                            existingQueryDataset.dataSetName == queryDataset.dataSetName
                            && existingQueryDataset.dataSourceName == queryDataset.dataSourceName
                            && existingQueryDataset.url == queryDataset.url
                        ) {
                            return existingQueryDataset;
                        }
                    }

                    return null;
                }

                let layerFoundCount = 0;
                let layerProcessedCount = 0;
                let resolveObj = {};

                let layersToIdentify = [];
                for (let i = 0; i < mapOLLayers.length; i++) {
                    let mapOLLayer = mapOLLayers[i];
                    if (mapOLLayer.queryDataset && !getDuplicateDataService(mapOLLayer.queryDataset)) {
                        layersToIdentify.push(mapOLLayer);
                        queryDatasetCache.push(mapOLLayer.queryDataset);
                    }

                    // If shapefile layer, call the API '/shp/search/attribute'
                    if (mapOLLayer.parentShp) {
                        layerFoundCount++;

                        let shpObj = mapOLLayer.parentShp;
                        // console.log(shpObj);
                        // console.log(geometry);
                        // console.log(projTransform(geometry.rings[0][0], 'EPSG:3857', 'EPSG:4326'));

                        // Transform picked geometry from 3857 to 4326;
                        let geometryWgs = {
                            rings: [[]]
                        };
                        for (let i = 0; i < geometry.rings[0].length; i++) {
                            geometryWgs.rings[0].push(projTransform(geometry.rings[0][i], 'EPSG:3857', 'EPSG:4326'));
                        }

                        let identifyShpPromise = shpObj.identify(geometryWgs);
                        identifyShpPromise.then(function (e) {
                            console.log('Layer Id: ', e.shpObj.tileLayer.id);
                            console.log('Identify data: ', e.data);

                            let layerId = e.shpObj.tileLayer.id;
                            if (e.data.length) {
                                if (!resolveObj[layerId]) {
                                    resolveObj[layerId] = [];
                                }

                                resolveObj[layerId] = resolveObj[layerId].concat(e.data);
                            }

                            layerProcessedCount++;
                            if (layerProcessedCount >= layerFoundCount) {
                                // console.log("resolveObj",resolveObj)
                                resolve(resolveObj);
                            }
                        });
                    }
                }
                // console.log("mapOLLayers",mapOLLayers,layersToIdentify)

                if (layerFoundCount) {
                    /// The following are all using pure SuperMap APIs. Too many problems here since we do not include SuperMap iClient here, so skip all these by now
                    return;

                    let pt2DArray, region;

                    // get current identify region
                    if (geometry.rings) {
                        if (geometry.rings.length > 1) {
                            var regions = [];
                            for (let ij = 0; ij < geometry.rings.length; ij++) {
                                pt2DArray = geometry.rings[ij][0];
                                // let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity;
                                let point2Ds = [];
                                for (let i = 0; i < pt2DArray.length; i++) {
                                    point2Ds.push(new SuperMap.Geometry.Point(pt2DArray[i][0], pt2DArray[i][1]));
                                }
                                let linearRings = new SuperMap.Geometry.LinearRing(point2Ds);
                                region = new SuperMap.Geometry.Polygon([linearRings]);
                                regions.push(region);
                            }
                            region = new SuperMap.Geometry.MultiPolygon(regions);
                        } else {
                            pt2DArray = geometry.rings[0];
                            // let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity;
                            let point2Ds = [];
                            for (let i = 0; i < pt2DArray.length; i++) {
                                point2Ds.push(new SuperMap.Geometry.Point(pt2DArray[i][0], pt2DArray[i][1]));
                            }
                            let linearRings = new SuperMap.Geometry.LinearRing(point2Ds);
                            region = new SuperMap.Geometry.Polygon([linearRings]);
                        }
                    } else if (geometry.line) {
                        pt2DArray = geometry.line;
                        let point2Ds = [];
                        for (let i = 0; i < pt2DArray.length; i++) {
                            point2Ds.push(new SuperMap.Geometry.Point(pt2DArray[i][0], pt2DArray[i][1]));
                        }
                        region = new SuperMap.Geometry.LineString(point2Ds);
                    }

                    layerFoundCount += layersToIdentify.length;

                    // Loop the identifiable layers
                    for (let i = 0; i < layerFoundCount; i++) {
                        let layerToIdentify = layersToIdentify[i];
                        if (!layerToIdentify)
                            break;

                        let queryObjs = layerToIdentify.queryDataset;

                        if (!Array.isArray(queryObjs)) {
                            queryObjs = [queryObjs];
                        }

                        // Mainly treat one layer as multiple if one layer is responsible for identifying many from DB.
                        // Might be messy if logic not strictly designed (Hope not)
                        // Same circumstance goes to 3D as well
                        layerFoundCount += (queryObjs.length - 1);

                        for (let j = 0; j < queryObjs.length; j++) {
                            let queryObj = queryObjs[j];

                            var getFeaturesByGeometryParameters, getFeaturesByGeometryService;
                            let dataSetNames = [queryObj.dataSourceName + ":" + queryObj.dataSetName]; // queryObj.dataSourceName + ":" + queryObj.dataSetName
                            if (all) {
                                dataSetNames = []
                            }
                            if (all && queryObj.dataSetName == "OZP_ZONE") {
                                let scale = this.getZoomScale();
                                // console.log("scale",zoomlevel,zoomlevel_ceil,scale);
                                // queryObj.dataSourceName + ":" + queryObj.dataSetName,
                                // queryObj.dataSourceName + ":HIST_OZP_AMEND" ,
                                // queryObj.dataSourceName + ":PT_DESC",
                                // queryObj.dataSourceName + ":BHC_ZONE"
                                // console.log("OZP_ZONE dataSetNames",dataSetNames)

                                // search dataset that satisify the scale condition
                                if (scale < 30000) {
                                    dataSetNames.push(queryObj.dataSourceName + ":" + queryObj.dataSetName);
                                    dataSetNames.push(queryObj.dataSourceName + ":OZP_AMEND");
                                    dataSetNames.push(queryObj.dataSourceName + ":OZP_AMEND_S6X");
                                    // dataSetNames.push(queryObj.dataSourceName + ":OZP_AMEND_S6X");
                                }
                                if (scale < 6000) {
                                    dataSetNames.push(queryObj.dataSourceName + ":BHC_ZONE");
                                    dataSetNames.push(queryObj.dataSourceName + ":PT_DESC");
                                }


                                // // openlayer get zoom level
                                // //  zoom level for OZP_ZONE  1:30000
                                // //  zoom level for BHC_ZONE  1:6000
                                // //  zoom level for PT_DESC  1:30000
                                // //  zoom level for OZP_AMEND  1:30000
                                // //  zoom level for OZP_AMEND_S6X 1:30000
                                // // console.log("OZP_ZONE dataSetNames",dataSetNames)
                            }
                            console.log("dataSetNames", dataSetNames)

                            let attributeFilter = queryObj.attributeFilter;
                            // let attributeFilter = undefined;
                            if (false) {
                                for (let iii in regions) {
                                    getFeaturesByGeometryParameters = new SuperMap.REST.GetFeaturesByGeometryParameters({
                                        datasetNames: dataSetNames,
                                        toIndex: -1,
                                        spatialQueryMode: SuperMap.REST.SpatialQueryMode.INTERSECT,
                                        geometry: regions[iii],
                                        //edit by jenny---start
                                        //groupBy:"ZONE_GRP",
                                        //fields:["ZONE_GRP"],
                                        //hasGeometry: false,
                                        //edit by jenny---end
                                        attributeFilter: attributeFilter
                                    });
                                    getFeaturesByGeometryService = new SuperMap.REST.GetFeaturesByGeometryService(window.Baseurl + queryObj.url, {
                                        eventListeners: {
                                            "processCompleted": intersectCompleted,
                                            "processFailed": function (e) {
                                                layerProcessedCount++;
                                                console.warn(e);

                                                if (layerProcessedCount >= layerFoundCount)
                                                    resolve(resolveObj);
                                            }

                                        }
                                    });
                                    getFeaturesByGeometryService.processAsync(getFeaturesByGeometryParameters);
                                }
                            } else {
                                getFeaturesByGeometryParameters = new SuperMap.REST.GetFeaturesByGeometryParameters({
                                    datasetNames: dataSetNames,
                                    toIndex: -1,
                                    spatialQueryMode: SuperMap.REST.SpatialQueryMode.INTERSECT,
                                    geometry: region,
                                    //edit by jenny---start
                                    //groupBy:"ZONE_GRP",
                                    //fields:["ZONE_GRP"],
                                    //hasGeometry: false,
                                    //edit by jenny---end
                                    attributeFilter: attributeFilter
                                });
                                getFeaturesByGeometryService = new SuperMap.REST.GetFeaturesByGeometryService(window.Baseurl + queryObj.url, {
                                    eventListeners: {
                                        "processCompleted": intersectCompleted,
                                        "processFailed": function (e) {
                                            layerProcessedCount++;
                                            console.warn(e);

                                            if (layerProcessedCount >= layerFoundCount)
                                                resolve(resolveObj);
                                        }

                                    }
                                });
                                getFeaturesByGeometryService.processAsync(getFeaturesByGeometryParameters);
                            }


                            function intersectCompleted(e) {
                                if (all) {
                                    function searchAmendS6x(res) {
                                        // console.log("searchAmendS6x e2")
                                        function intersectCompleted2(e2) {
                                            // console.log("intersectCompleted e2",e2)

                                            let result = e2.result;
                                            let features = result.features;

                                            if (features.length > 0) {
                                                let sourceName = "OZP_ZONE";

                                                if (!resolveObj[sourceName])
                                                    resolveObj[sourceName] = [];

                                                for (let i = 0; i < features.length; i++) {
                                                    var geomRaw = e2.originResult.features[i].geometry;
                                                    if (geomRaw) {
                                                        var geojson = smRawGeomToGeoJSONs(geomRaw);
                                                        features[i].data.geometry = geojson;
                                                        features[i].data.AMEND_ITEM_NO = "s6x"
                                                        // console.log("add features[i].data",features[i].data)
                                                        resolveObj[sourceName].push(features[i].data);
                                                    }
                                                }
                                            }
                                            res()
                                        }

                                        var getFeaturesByGeometryParameters2, getFeaturesByGeometryService2;
                                        getFeaturesByGeometryService2 = new SuperMap.REST.GetFeaturesByGeometryService(window.Baseurl + queryObj.url, {
                                            eventListeners: {
                                                "processCompleted": intersectCompleted2,
                                                "processFailed": function (e2) {
                                                    console.error("process fail", e2);
                                                }
                                            }
                                        });

                                        // console.log("queryObj.dataSourceName ",queryObj.dataSourceName + ":HIST_OZP_AMEND_S6X",) // HIST_OZP_AMEND
                                        getFeaturesByGeometryParameters2 = new SuperMap.REST.GetFeaturesByGeometryParameters({
                                            datasetNames: [queryObj.dataSourceName + ":OZP_AMEND_S6X"],
                                            toIndex: -1,
                                            spatialQueryMode: SuperMap.REST.SpatialQueryMode.INTERSECT,
                                            geometry: region
                                        })
                                        getFeaturesByGeometryService2.processAsync(getFeaturesByGeometryParameters2);
                                    }

                                    if (scale < 30000 && false) {
                                        let searchAmendPromise = new Promise((res, reject) => {
                                            searchAmendS6x(res);
                                            // setTimeout(()=>{
                                            // res()
                                            // },2000)
                                        })
                                        searchAmendPromise.then(() => {
                                            // console.log("intersectCompleted e",e)
                                            let result = e.result;
                                            let features = result.features;

                                            if (features.length) {
                                                let originalSourceInfo = JSON.parse(e.object.options.data.replace(/\'/g, '"')).datasetNames[0].split(':');
                                                let sourceName = originalSourceInfo[1].toUpperCase();

                                                if (!resolveObj[sourceName])
                                                    resolveObj[sourceName] = [];

                                                for (let i = 0; i < features.length; i++) {
                                                    var geomRaw = e.originResult.features[i].geometry;
                                                    if (geomRaw) {
                                                        var geojson = smRawGeomToGeoJSONs(geomRaw);
                                                        features[i].data.geometry = geojson;
                                                        resolveObj[sourceName].push(features[i].data);
                                                    }
                                                }
                                            }
                                            layerProcessedCount++;
                                            if (layerProcessedCount >= layerFoundCount) {
                                                // console.log("resolveObj",resolveObj)
                                                resolve(resolveObj);
                                            }
                                        })
                                    } else {
                                        resolve(resolveObj);
                                    }
                                } else {
                                    // console.log("single intersectCompleted e",e)

                                    let result = e.result;
                                    let features = result.features;

                                    if (features.length) {
                                        var returnObj;
                                        let cmdStr = 'returnObj = ' + e.object.options.data;
                                        eval(cmdStr);
                                        // let originalSourceInfo = JSON.parse(e.object.options.data.replaceAll('"', '\"').replace(/\'/g, '"')).datasetNames[0].split(':');
                                        let originalSourceInfo = returnObj.datasetNames[0].split(':');
                                        let sourceName = originalSourceInfo[1].toUpperCase();

                                        if (!resolveObj[sourceName])
                                            resolveObj[sourceName] = [];

                                        for (let i = 0; i < features.length; i++) {
                                            var geomRaw = e.originResult.features[i].geometry;
                                            if (geomRaw) {
                                                var geojson = smRawGeomToGeoJSONs(geomRaw);
                                                features[i].data.geometry = geojson;
                                                resolveObj[sourceName].push(features[i].data);
                                            }
                                        }
                                    }
                                    layerProcessedCount++;

                                    if (layerProcessedCount >= layerFoundCount) {
                                        // console.log("resolveObj",resolveObj)
                                        resolve(resolveObj);
                                    }
                                }
                            }
                        }
                    }
                    return;
                } else {
                    // console.log("none sm_map")
                    // Window.vui.syncMapLegend(null)
                    resolve(null)
                }
                return;
            }

            var imageryLayers = window.viewer.imageryLayers._layers;
            var sceneLayers = window.viewer.scene.layers;

            let totalLayerNumbers = imageryLayers.length //+ sceneLayers._layers._array.length

            var iLayerFound = 0,
                iLayerProcessed = 0;
            var identifyResult = {};
            if (imageryLayers.length == 0) {
                // console.log("none sm_map")
                // Window.vui.syncMapLegend(null)
            }
            if (sceneLayers.length == 0) {
                // console.log("none sceneLayers")
            }
            // console.log("imageryLayers sceneLayers",imageryLayers.length,sceneLayers._layers._array.length)

            for (var i = 0; i < imageryLayers.length; i++) {
                var imageryLayer = imageryLayers[i];
                var imageryProvider = imageryLayer._imageryProvider;
                // console.log("imageryProvider",imageryProvider)

                if (imageryProvider instanceof CesiumPlugin.ArcGISFeatureLayer) {
                    iLayerFound++;

                    imageryProvider.name = imageryLayer.name;
                    var promise = imageryProvider.identify(geometry);
                    promise.then(function (result) {
                        if (result.features.length > 0)
                            identifyResult[result.layer] = result.features;

                        iLayerProcessed++;
                        if (iLayerFound == iLayerProcessed)
                            resolve(identifyResult);
                    });
                } else if (imageryProvider instanceof CesiumPlugin.SuperMapDataServiceLayer) {
                    iLayerFound++;

                    var promise = imageryProvider.identify(geometry);

                    promise.then(function (result) {
                        console.log(result);
                        /*
                        if (result)
                          identifyResult[result.layer] = result.features;

                        iLayerProcessed++;
                        if (iLayerFound == iLayerProcessed && Object.keys(identifyResult).length)
                          resolve(identifyResult);
                         */
                    });
                } else if (imageryProvider instanceof CesiumPlugin.SDMSShpLayer) {
                    iLayerFound++;

                    if (imageryProvider.dimension == 2) {
                        var promise = imageryProvider.identify(geometry);

                        promise.then(function (result) {
                            if (result && result[0]) {
                                let resultAdj = {};
                                resultAdj.ID = result[0].ogc_fid;
                                delete (result[0].ogc_fid)

                                for (let p in result[0]) {
                                    resultAdj[p] = result[0][p] ? result[0][p] : ' ';
                                }
                                identifyResult[imageryProvider.tableName] = [resultAdj];
                            }

                            iLayerProcessed++;

                            identifyResult.isShp = true;
                            if (iLayerFound == iLayerProcessed && Object.keys(identifyResult).length)
                                resolve(identifyResult);
                        });
                    } else {
                        iLayerProcessed++;
                    }
                } else if (imageryLayer.queryDataset) {
                    iLayerFound++;
                    // console.log("SuperMapMapServiceLayer")
                    /// HARDCODE to regard all data source of SuperMap Map services to be in HK80
                    let isSmMap80 = imageryLayer.imageryProvider instanceof CesiumPlugin.SuperMapMapServiceLayer;

                    // console.log(imageryLayer.queryDataset);
                    let pt2DArray, region;
                    if (geometry.rings) {
                        pt2DArray = geometry.rings[0];
                        pt2DArray[0][0] > 180 || pt2DArray[0][0] < -180 ? isSmMap80 = false : isSmMap80 = true;  // tranform if needed
                        // let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity;
                        // console.log("has ring,isSmMap80",isSmMap80)
                        let point2Ds = [];
                        for (let i = 0; i < pt2DArray.length; i++) {
                            if (!isSmMap80)
                                point2Ds.push(new SuperMap.Geometry.Point(pt2DArray[i][0], pt2DArray[i][1]));
                            else {
                                var hk80pt = Util.CoordTransform.translate({
                                    fromEPSG: '4326',
                                    toEPSG: '2326',
                                    x: pt2DArray[i][0],
                                    y: pt2DArray[i][1]
                                });
                                point2Ds.push(new SuperMap.Geometry.Point(hk80pt[0], hk80pt[1]));
                            }
                        }
                        let linearRings = new SuperMap.Geometry.LinearRing(point2Ds);
                        region = new SuperMap.Geometry.Polygon([linearRings]);
                    } else if (geometry.line) {
                        pt2DArray = geometry.line;
                        pt2DArray[0][0] > 180 || pt2DArray[0][0] < -180 ? isSmMap80 = false : isSmMap80 = true;
                        let point2Ds = [];
                        for (let i = 0; i < pt2DArray.length; i++) {
                            if (!isSmMap80)
                                point2Ds.push(new SuperMap.Geometry.Point(pt2DArray[i][0], pt2DArray[i][1]));
                            else {
                                var hk80pt = Util.CoordTransform.translate({
                                    fromEPSG: '4326',
                                    toEPSG: '2326',
                                    x: pt2DArray[i][0],
                                    y: pt2DArray[i][1]
                                });
                                point2Ds.push(new SuperMap.Geometry.Point(hk80pt[0], hk80pt[1]));
                            }
                        }
                        region = new SuperMap.Geometry.LineString(point2Ds);
                    }

                    let queryObjs = imageryLayer.queryDataset;
                    if (!Array.isArray(queryObjs)) {
                        queryObjs = [queryObjs];
                    }

                    totalLayerNumbers += (queryObjs.length - 1);
                    for (let j = 0; j < queryObjs.length; j++) {
                        let queryObj = queryObjs[j];

                        var getFeaturesByGeometryParameters, getFeaturesByGeometryService;
                        let dataSetNames = [queryObj.dataSourceName + ":" + queryObj.dataSetName];

                        if (all && queryObj.dataSetName == "OZP_ZONE") {
                            dataSetNames = [queryObj.dataSourceName + ":" + queryObj.dataSetName,
                                queryObj.dataSourceName + ":HIST_OZP_AMEND",
                                queryObj.dataSourceName + ":PT_DESC",
                                queryObj.dataSourceName + ":BHC_ZONE"
                            ];
                            // console.log("OZP_ZONE dataSetNames",dataSetNames)
                        }
                        getFeaturesByGeometryParameters = new SuperMap.REST.GetFeaturesByGeometryParameters({
                            datasetNames: dataSetNames,
                            toIndex: -1,
                            spatialQueryMode: SuperMap.REST.SpatialQueryMode.INTERSECT,
                            geometry: region
                        });
                        getFeaturesByGeometryService = new SuperMap.REST.GetFeaturesByGeometryService(window.Baseurl + queryObj.url, {
                            eventListeners: {
                                "processCompleted": intersectCompleted,
                                "processFailed": function (e) {
                                    console.warn(e);
                                }
                            }
                        });
                        getFeaturesByGeometryService.processAsync(getFeaturesByGeometryParameters);

                        function intersectCompleted(e) {
                            // console.log("intersectCompleted e",e,"all",all)
                            if (all) {
                                function searchAmendS6x() {
                                    // console.log("searchAmendS6x e2")
                                    function intersectCompleted2(e2) {
                                        // console.log("intersectCompleted e2",e2)

                                        let result = e2.result;
                                        let features = result.features;

                                        if (features.length > 0) {
                                            let sourceName = "OZP_ZONE";

                                            if (!resolveObj[sourceName])
                                                resolveObj[sourceName] = [];

                                            for (let i = 0; i < features.length; i++) {
                                                var geomRaw = e2.originResult.features[i].geometry;
                                                if (geomRaw) {
                                                    var geojson = smRawGeomToGeoJSONs(geomRaw);
                                                    features[i].data.geometry = geojson;
                                                    features[i].data.AMEND_ITEM_NO = "s6x"
                                                    console.log("add features[i].data", features[i].data)
                                                    resolveObj[sourceName].push(features[i].data);
                                                }
                                            }
                                        }
                                    }

                                    var getFeaturesByGeometryParameters2, getFeaturesByGeometryService2;
                                    getFeaturesByGeometryService2 = new SuperMap.REST.GetFeaturesByGeometryService(window.Baseurl + queryObj.url, {
                                        eventListeners: {
                                            "processCompleted": intersectCompleted2,
                                            "processFailed": function (e2) {
                                                console.error("process fail", e2);
                                            }
                                        }
                                    });

                                    // console.log("queryObj.dataSourceName ",queryObj.dataSourceName + ":HIST_OZP_AMEND_S6X",) // HIST_OZP_AMEND
                                    getFeaturesByGeometryParameters2 = new SuperMap.REST.GetFeaturesByGeometryParameters({
                                        datasetNames: [queryObj.dataSourceName + ":HIST_OZP_AMEND_S6X"],
                                        toIndex: -1,
                                        spatialQueryMode: SuperMap.REST.SpatialQueryMode.INTERSECT,
                                        geometry: region
                                    })
                                    getFeaturesByGeometryService2.processAsync(getFeaturesByGeometryParameters2);

                                }

                                let searchAmendPromise = new Promise((res, reject) => {
                                    searchAmendS6x();
                                    setTimeout(() => {
                                        res()
                                    }, 500)
                                })
                                searchAmendPromise.then(() => {
                                    // console.log("intersectCompleted e",e)
                                    let result = e.result;
                                    let features = result.features;

                                    if (features.length) {
                                        let originalSourceInfo = JSON.parse(e.object.options.data.replace(/\'/g, '"')).datasetNames[0].split(':');
                                        let sourceName = originalSourceInfo[1].toUpperCase();

                                        if (!identifyResult[sourceName])
                                            identifyResult[sourceName] = [];

                                        for (let i = 0; i < features.length; i++) {
                                            var geomRaw = e.originResult.features[i].geometry;
                                            if (geomRaw) {
                                                var geojson = smRawGeomToGeoJSONs(geomRaw);
                                                features[i].data.geometry = geojson;
                                                identifyResult[sourceName].push(features[i].data);
                                            }
                                        }
                                    }
                                    iLayerProcessed++;
                                    // console.log("iLayerProcessed,iLayerFound",iLayerFound,iLayerProcessed)
                                    if (Object.keys(identifyResult).length && iLayerProcessed >= totalLayerNumbers) {
                                        // console.log("resolved 0",identifyResult)
                                        resolve(identifyResult);
                                    }

                                })
                            } else {
                                let result = e.result;
                                let features = result.features;
                                if (features.length) {
                                    let originalSourceInfo = JSON.parse(e.object.options.data.replace(/\'/g, '"')).datasetNames[0].split(':');
                                    let sourceName = originalSourceInfo[1].toUpperCase();
                                    // let resolveObj = {};
                                    // let featureList;
                                    // identifyResult[sourceName] = [features[0].data];
                                    if (!identifyResult[sourceName])
                                        identifyResult[sourceName] = [];
                                    for (let i = 0; i < features.length; i++) {
                                        var geomRaw = e.originResult.features[i].geometry;
                                        if (geomRaw) {
                                            var geojson = smRawGeomToGeoJSONs(geomRaw, true);
                                            features[i].data.geometry = geojson;
                                            identifyResult[sourceName].push(features[i].data);
                                        }
                                    }
                                }
                                iLayerProcessed++;

                                // console.log("iLayerProcessed,iLayerFound",iLayerFound,iLayerProcessed)
                                if (Object.keys(identifyResult).length && iLayerProcessed >= totalLayerNumbers) {
                                    // console.log("resolved 1",identifyResult)
                                    // identify3DLayers();
                                    resolve(identifyResult);
                                }
                            }
                        }
                    }
                } else {
                    iLayerFound++
                    iLayerProcessed++
                    if (iLayerProcessed >= totalLayerNumbers) {
                        resolve(identifyResult);
                    }
                }
            }
            ;

            // // for scene layes
            // if (window.viewer.scene.layers) {
            // var layers3D = window.viewer.scene.layers._layers._array;
            // for (var i = 0; i < layers3D.length; i++) {
            // var layer3D = layers3D[i];
            // if (layer3D.queryDataset) {
            // iLayerFound++;

            // var layer3DSelection = layer3D.getSelection();
            // var promise = that.identify3DLayer(layer3D.name, layer3D.queryDataset, layer3DSelection);

            // promise.then(function(result) {
            // if (result.features.length > 0)
            // identifyResult[result.layer] = result.features;

            // iLayerProcessed++;
            // if (iLayerFound == iLayerProcessed && iLayerProcessed >= totalLayerNumbers) {
            // // console.log("3d layer ")
            // //If any 3D layer selected, display the 3d attribute, which means to skip the 2d attribute beneath
            // if (Object.keys(identifyResult).length) {
            // resolve(identifyResult);

            // }
            // }
            // });
            // }
            // }
            // }

            // console.log("resolve null")
            // resolve(identifyResult?identifyResult:null);
        });
    }

    addFootPrint(footprints) {
        var style = new Style({
            image: new Circle({
                radius: 7,
                stroke: new Stroke({
                    color: 'rgba(200,200,200,1.0)',
                    width: 3,
                }),
                fill: new Fill({
                    color: 'rgba(255,0,0,1.0)'
                })
            }),
            text: new Text({
                font: 'bold 20px "Open Sans", "Arial Unicode MS", "sans-serif"',
                placement: 'point',
                fill: new Fill({color: '#fff'}),
                stroke: new Stroke({color: '#000', width: 2}),
                offsetY: -9
            }),
        });

        var styleFunction = function (feature) {
            style.getText().setText(feature.get('name'));
            return style;
        }
        if (footprints.length > 0) {
            let vectorSource = new VectorSource({wrapX: false});
            let vectorLayer = new VectorLayer({
                maxZoom: 15,
                source: vectorSource,
                style: styleFunction,
                name: 'footprints'
            });
            for (var i = 0; i < footprints.length; i++) {
                var geometry = footprints[i].geometry
                if (geometry != null) {
                    console.log('add footprint')
                    const obj = JSON.parse(geometry);
                    var boundary = new GeoJSON().readFeature(obj)
                    var center = getCenter(boundary.getGeometry().getExtent())

                    let coord = CoordTransform.translate({
                        fromEPSG: '4326',
                        toEPSG: '3857',
                        x: center[0],
                        y: center[1]
                    });

                    let ptFeature = new Feature({
                        geometry: new Point([
                            coord[0],
                            coord[1]
                        ]),
                        name: footprints[i].name,
                        type: 'footprint',
                        layerId: footprints[i].layerId
                    });

                    vectorSource.addFeature(ptFeature)
                }
            }
            console.log('add footprintlayer')

            window.mapOL.addLayer(vectorLayer)
        }
    }
}


export default MapOL;
