

<style lang="less">
  @import "./PhotoCompareViewer.less";
</style>

<template>
  <div class="photo-compare-viewer">
    <Modal
      ref="photoCompareView"
      :closable="false"
      :mask-closable="false"
      :mask="false"
      v-model="isShowInternal"
      class="photo-compare-modal"
    >
      <div slot="header">

      </div>

      <div class="modal-close-btn" @click="close">
        <Button type="text">
          <i class="fas fa-times"></i>
        </Button>
      </div>

      <Row v-if="allSelectPhotos.length == 2" class="tworow">
        <Col span="12" v-for="(item, idx) in allSelectPhotos" :key="idx">
          <div class="imgtitle">{{ item.flyPlan }} - {{ item.imgOrder }}</div>
          <div :id="`img${idx}`" style="width: 100%;height: 100%"></div>
        </Col>
      </Row>

      <Row
        v-if="allSelectPhotos.length == 4 || allSelectPhotos.length == 3"
        class="fourrow"
      >
        <Col span="12" v-for="(item, idx) in allSelectPhotos" :key="idx">
          <div class="imgtitle">{{ item.flyPlan }} - {{ item.imgOrder }}</div>
          <div :id="`img${idx}`" style="width: 100%;height: 100%"/>
        </Col>
      </Row>

      <Row
        v-if="allSelectPhotos.length == 5 || allSelectPhotos.length == 6"
        class="sixrow"
      >
        <Col span="8" v-for="(item, idx) in allSelectPhotos" :key="idx">
          <div class="imgtitle">{{ item.flyPlan }} - {{ item.imgOrder }}</div>
          <div :id="`img${idx}`" style="width: 100%;height: 100%"/>
        </Col>
      </Row>

      <Input v-model="emailaddress" placeholder="Please input your email">
        <template #append>
          <span>@pland.gov.hk</span>
        </template>
      </Input>
      <Button @click="downloadALLImages" :disabled="emailaddress===''" type="primary">Download</Button>

      <div slot="footer"></div>
    </Modal>
  </div>
</template>

<script>
import "iv-viewer/dist/iv-viewer.css";
import Service from "../../libs/service.js";
import OpenSeadragon from "openseadragon";

export default {
  name: 'PhotoCompareViewer',
  props: {
    allSelectPhotos: {
      type: Array,
      default: () => []
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  computed: {

  },
  data() {
    return {
      isShowInternal: false,
      loadViewerTimeoutIdx: undefined,
      viewerlist: [],
      emailaddress:''
    }
  },
  watch: {
    allSelectPhotos: {
      deep: true,
      immediate: true,
      handler (newVal, oldVal) {
        /// Any TODO here?

        // Debounce
        if(this.loadViewerTimeoutIdx) { return; }

        let that = this;
        this.loadViewerTimeoutIdx = setTimeout(function() {
          delete(that.loadViewerTimeoutIdx);

          for(var i = 0;i<that.allSelectPhotos.length;i++){
            var d = that.allSelectPhotos[i]
            const viewer = OpenSeadragon({
              id: 'img'+i,
              preventViewport:true,
              showNavigationControl:false,
              defaultZoomLevel:0.8,
              tileSources: {
                type: 'image',
                url:  d.url
              }
            });
            that.viewerlist.push(viewer);
          }

          var viewer1Leading = false;
          var viewer2Leading = false;
          var viewer3Leading = false;
          var viewer4Leading = false;
          var viewer5Leading = false;
          var viewer0Leading = false;

          var viewer0Handler = function() {
            if (viewer2Leading || viewer3Leading || viewer4Leading || viewer5Leading || viewer1Leading) {
              return;
            }
            viewer0Leading = true;
            for(var i=0;i<that.viewerlist.length;i++){
              if(i!==0  && that.viewerlist.length>0) {
                that.viewerlist[i].viewport.zoomTo(that.viewerlist[0].viewport.getZoom());
                that.viewerlist[i].viewport.panTo(that.viewerlist[0].viewport.getCenter());
              }
            }
            viewer0Leading = false;
          };
          if(that.viewerlist.length>0) {
            that.viewerlist[0].addHandler('zoom', viewer0Handler);
            that.viewerlist[0].addHandler('pan', viewer0Handler);
          }

          var viewer1Handler = function() {
            if (viewer2Leading || viewer3Leading || viewer4Leading || viewer5Leading || viewer0Leading) {
              return;
            }
            viewer1Leading = true;
            for(var i=0;i<that.viewerlist.length;i++){
              if(i!==1 && that.viewerlist.length>1) {
                that.viewerlist[i].viewport.zoomTo(that.viewerlist[1].viewport.getZoom());
                that.viewerlist[i].viewport.panTo(that.viewerlist[1].viewport.getCenter());
              }
            }
            viewer1Leading = false;
          };
          if(that.viewerlist.length>1) {
            that.viewerlist[1].addHandler('zoom', viewer1Handler);
            that.viewerlist[1].addHandler('pan', viewer1Handler);
          }

          var viewer2Handler = function() {
            if (viewer0Leading || viewer3Leading || viewer4Leading || viewer5Leading || viewer1Leading) {
              return;
            }
            viewer2Leading = true;
            for(var i=0;i<that.viewerlist.length;i++){
              if(i!==2 && that.viewerlist.length>2) {
                that.viewerlist[i].viewport.zoomTo(that.viewerlist[2].viewport.getZoom());
                that.viewerlist[i].viewport.panTo(that.viewerlist[2].viewport.getCenter());
              }
            }
            viewer2Leading = false;
          };
          if(that.viewerlist.length>2) {
            that.viewerlist[2].addHandler('zoom', viewer2Handler);
            that.viewerlist[2].addHandler('pan', viewer2Handler);
          }

          var viewer3Handler = function() {
            if (viewer2Leading || viewer0Leading || viewer4Leading || viewer5Leading || viewer1Leading) {
              return;
            }
            viewer3Leading = true;
            for(var i=0;i<that.viewerlist.length;i++){
              if(i!==3 && that.viewerlist.length>3) {
                that.viewerlist[i].viewport.zoomTo(that.viewerlist[3].viewport.getZoom());
                that.viewerlist[i].viewport.panTo(that.viewerlist[3].viewport.getCenter());
              }
            }
            viewer3Leading = false;
          };
          if(that.viewerlist.length>3) {
            that.viewerlist[3].addHandler('zoom', viewer3Handler);
            that.viewerlist[3].addHandler('pan', viewer3Handler);
          }

          var viewer4Handler = function() {
            if (viewer2Leading || viewer3Leading || viewer0Leading || viewer5Leading || viewer1Leading) {
              return;
            }
            viewer4Leading = true;
            for(var i=0;i<that.viewerlist.length;i++){
              if(i!==4 && that.viewerlist.length>4) {
                that.viewerlist[i].viewport.zoomTo(that.viewerlist[4].viewport.getZoom());
                that.viewerlist[i].viewport.panTo(that.viewerlist[4].viewport.getCenter());
              }
            }
            viewer4Leading = false;
          };
          if(that.viewerlist.length>4) {
            that.viewerlist[4].addHandler('zoom', viewer4Handler);
            that.viewerlist[4].addHandler('pan', viewer4Handler);
          }

          var viewer5Handler = function() {
            if (viewer2Leading || viewer3Leading || viewer4Leading || viewer0Leading || viewer1Leading) {
              return;
            }
            viewer0Leading = true;
            for(var i=0;i<that.viewerlist.length;i++){
              if(i!==5 && that.viewerlist.length>5) {
                that.viewerlist[i].viewport.zoomTo(that.viewerlist[5].viewport.getZoom());
                that.viewerlist[i].viewport.panTo(that.viewerlist[5].viewport.getCenter());
              }
            }
            viewer0Leading = false;
          };
          if(that.viewerlist.length>5) {
            that.viewerlist[5].addHandler('zoom', viewer5Handler);
            that.viewerlist[5].addHandler('pan', viewer5Handler);
          }
        }, 500);
      }
    },
    isShow: {
      handler (newVal, oldVal) {
        this.isShowInternal = newVal;
      }
    }
  },
  mounted() {

  },
  methods: {
    close() {
      this.viewerlist.forEach(function (d, i) {
        d.destroy();
      });
      this.viewerlist = [];

      this.isShowInternal = false;
      this.$emit('on-close');
    },
    downloadALLImages(){
      console.log(this.allSelectPhotos)
      console.log(this.emailaddress)
      var mediaIds = []
      var name = ''
      for(var i = 0; i< this.allSelectPhotos.length;i++)
      {
        mediaIds.push(this.allSelectPhotos[i].mediaId.toString())
        name+=this.allSelectPhotos[i].flyPlan+'-'+this.allSelectPhotos[i].imgOrder+","
      }
      var data = {}
      data.flyPlanName = name
      data.emailaddress = this.emailaddress.endsWith("@raspect.ai")? this.emailaddress: this.emailaddress+'@pland.gov.hk'
      data.mediaIds = mediaIds
      Service.request("post","/sys/media/download",
      data,function(dt){},function (err){})
    }
  }
}
</script>
