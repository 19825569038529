import DrawOL from './DrawOL.js';

import {
Point as GeomPoint
} from 'ol/geom';
import {
Icon as StyleIcon,
Style
} from 'ol/style';
import Feature from 'ol/Feature';


class LocationSearchDrawOL extends DrawOL {

	constructor(map, options) {
		super(map, options, "Symbol");

    this.url = options.url || './images/pin_icon/Pin Icon-16.png'; //https://mapapis01.blob.core.windows.net/images/location.png
		this.style = new Style({
			image: new StyleIcon({
				anchor: [0.5, 0.5],
				src: this.url, //still need something even if it's invisible
				opacity: 1,
				scale: 0.2
			})
		});
		this._features = []
	}

	highlightSymbol(index,bearing,isP,isOld = false) {
		this._feature = this.source.getFeatureById(index);
		var tmpStyle = this._feature.getStyle();
		if (!isOld) {
			this.url = isP ? './images/pin_icon/PanoSP_selected.png' : './images/pin_icon/SitePhoto_selected.png';
		} else {
			this.url = isP ? './images/pin_icon/PanoSP.png' : './images/pin_icon/SitePhoto.png';
		}
    bearing = bearing * (Math.PI/180);
		tmpStyle = new Style({
			image: new StyleIcon({
				anchor: [0.5, 1],
				src: this.url,
				scale: 1,
				rotation: bearing
			})
		})
		this._feature.setStyle(tmpStyle);
		this.flyTo();
	}

	addMarker(lon, lat, bearing, ID, isPano = false) {
		if (isPano) {
			this.url = './images/pin_icon/PanoSP.png';
		} else {
			this.url = './images/pin_icon/SitePhoto.png';
		}
		this._feature = new Feature({
			geometry: new GeomPoint([lon, lat])
		})

		this.style = new Style({
			image: new StyleIcon({
				anchor: [0.5, 1],
				src: this.url,
				scale: 1,
				rotation: bearing
			})
		})
		this._feature.setId(ID);
		this._feature.setStyle(this.style);

		this.source.addFeature(this._feature);
		this._features.push(this._feature)
	}

	addSymbol(lon, lat, height) {
		this._feature = new Feature({
			geometry: new GeomPoint([lon, lat])
		})
		this._feature.setStyle(this.style);
		this.source.addFeature(this._feature);

    this._feature.isMarker = true;
		this._features.push(this._feature)
		this.flyTo();
	}

	// initializing acoording to the drawtype
	init() {
		if (that._onFinished) {
			that._onFinished();
			that._onFinished = undefined;
		}

	}

	show(val){
		this._features.forEach((_feature) => {
			if (_feature) {
				if(val)
					_feature.setStyle(this.style);
				else
					_feature.setStyle(new Style());
			}
		})
	}

	clear() {
		let that = this
		this._features.forEach((_feature) => {
			that.source.removeFeature(_feature);
			if (_feature) {
				_feature.dispose();
				_feature = undefined;
			}
		})
		this._feature = null;
		this._features = []
	}

	get pointIconUrl() {
		return this.url
	}

	// set the point Icon, only works for the Point type
	setPointIcon(url = 'https://openlayers.org/en/v3.20.1/examples/data/icon.png', option={}) {
    let anchor = [0.5, 1];
    if(url == './images/pin_icon/start_point.png'){
      anchor = [0,1];
    }
		this.url = url;
		this.style = new Style({
			image: new StyleIcon({
				anchor: anchor,
				src: this.url, //still need something even if it's invisible
				opacity: 1
			})
		});
		if(option.scale){
			this.style.getImage().setScale(option.scale);
		}
		this._feature.setStyle(this.style);
	}

}

export default LocationSearchDrawOL;
