// ---------------------------- OpenLayers libs --------------------------------
import {
  Vector as LayerVector
} from 'ol/layer';
import {
  Vector as SourceVector
} from 'ol/source';
// ------------------------ End of OpenLayers libs -----------------------------

class OpenLayersFunctions {
  constructor () { ; }
  
  static getVectorLayer (mapOL) {
    let layerList = mapOL.getLayers().getArray();
    let vectorLayer;
    for(let i=0; i<layerList.length; i++) {
      let theLayer = layerList[i];
      if(theLayer instanceof LayerVector && theLayer.isForDrawings) {
        vectorLayer = theLayer;
        continue;
      }
    }
    
    if(!vectorLayer) {
      vectorLayer = new LayerVector({
        source: new SourceVector({wrapX: false})
      });
      vectorLayer.setZIndex(1e4);
      vectorLayer.isForDrawings = true;
      mapOL.addLayer(vectorLayer);
    }
    
    return vectorLayer;
  }
  
  static getAllFeaturesInVectorLayer (mapOL) {
    let layerList = mapOL.getLayers().getArray();
    let featurelist = [];
    let vectorLayer;
    for(let i=0; i<layerList.length; i++) {
      let theLayer = layerList[i];
      if(theLayer instanceof LayerVector && theLayer.isForDrawings) {
        vectorLayer = theLayer;
        featurelist.push.apply(featurelist, vectorLayer.getSource().getFeatures())
      }
    }
     
    return featurelist;
  }
  
  static getVectorLayer_bk (mapOL) {
    let layerList = mapOL.getLayers().getArray();
    let vectorLayer;

    vectorLayer = new LayerVector({
      source: new SourceVector({wrapX: false})
    });
    vectorLayer.setZIndex(1e4);
    vectorLayer.isForDrawings = true;
    mapOL.addLayer(vectorLayer);
    
    
    return vectorLayer;
  }
  
  static exportGeojson(){
    var GeoJSON = new GeoJSON();
    
    let layerList = window.mapOL.getLayers().getArray();
    let vectorLayer;
    var count = 0;
    console.log("layerList",layerList);
    for(let i=0; i<layerList.length; i++) {
      let theLayer = layerList[i];
      if(theLayer instanceof LayerVector && theLayer.isForDrawings) {
        vectorLayer = theLayer;
        count += 1
        continue;
       
        // continue;
      }
    }
    console.log("vector layer count", count);
    
    if(vectorLayer){
      var features= vectorLayer.getSource().getFeatures();
      var geoJsonObj = {
        "point":{ 
          "type":"FeatureCollection",
          "features":[]
        },
        "linestring":{ 
          "type":"FeatureCollection",
          "features":[]
        },
        "polygon":{ 
          "type":"FeatureCollection",
          "features":[]
        },
      };
      //var geoJsonObj = GeoJSON.writeFeaturesObject(features);
      features.forEach(function(item){
        var itemStyle = item.getStyle()
        var itemGeo = GeoJSON.writeFeatureObject(item)
        console.log("Style",item.getStyle(),typeof(itemStyle))
        console.log("itemGeo",itemGeo)
        let geotype = itemGeo.geometry.type.toLowerCase();
        
        if (itemGeo.properties["type"] != "drop"){
          var stylekeys = ["fill_color","image_","stroke_color","stroke_width"];// Object.keys(itemStyle);
          itemGeo.properties["fill_color"] = itemStyle["fill_"].color_;
          itemGeo.properties["stroke_color"] = itemStyle["stroke_"].color_;
          itemGeo.properties["stroke_width"] = itemStyle["stroke_"].width_;
          //stylekeys.forEach(function(styleKey){
          //   itemGeo.properties[styleKey] = itemStyle[styleKey]
          //} )
          
          // all properties
          //itemGeo.properties["style"] = itemStyle;
          
          geoJsonObj[geotype].features.push(itemGeo)
        }
      })

      console.log(vectorLayer.getSource(),features,"geoJsonObj",geoJsonObj);
    }
    
    
    return geoJsonObj
  }
}

export default OpenLayersFunctions;




