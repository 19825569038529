<style lang="less">
@import "./viewer.less";
@import "../../assets/styles/css/cropper.min.css";
</style>

<template>
  <div id="appViewer">
    <div
        class="map-row"
    >
      <!-- 加载提示 -->
      <Spin
          size="large"
          fix
          v-if="spinShowNoProgress"
      ></Spin>
      <Spin
          size="small"
          fix
          v-if="spinShow"
      >
        <i-circle
            :percent="processPercent"
            :stroke-color="processColor"
            :stroke-width="4"
            :size="80"
            :trail-width="3"
        >
          <Icon
              v-if="processPercent == 100"
              type="ios-checkmark"
              size="60"
              style="color: #19be6b"
          ></Icon>
          <span
              v-else
              style="font-size: 20px; color: #19be6b"
          >{{ processPercent }}%</span>
        </i-circle>
      </Spin>
      <!-- 地图功能组件  switch23Dv2 lock2D-->
      <div
          class="map-operations viewer"
      >
        <div @click="showFlyPlanPanel" class="map-operation-item">
          <i class="fas fa-calendar-alt"></i>
          <span>{{ $t('x_flyplan') }}</span>
        </div>

        <Poptip
            placement="bottom-start"
            v-model="pop.basemap.on"
        >
          <div class="map-operation-item">
            <i class="fas fa-globe"></i> <span>{{ $t('x_baselayer') }}</span>
            <div class="triangle-sm-fixed"></div>
          </div>
          <div
              class="pop-content"
              slot="content"
          >
            <div class="basemaps">
              <div class="basemap-group">
                <div class="basemap-group-header">{{ $t('x_base') }}</div>
                <div
                    :class="{
                    'basemap-item': true,
                    'basemap-item-highlight': (baseLayer2Didxs.includes(Number(m.value)) && !is2D) || (baseLayer2Didx == Number(m.value) && !is2D) || (baseLayer2DidxsDD.includes(Number(m.value)) && is2D) || (baseLayer2DidxDD == Number(m.value) && is2D),
                  }"
                    @click="setBaseMapUIDD(m.value, m.isOnly)"
                    v-for="(m, idx) in baseLayer2DItems"
                    :key="idx"
                >
                  <img
                      :src="m.image"
                      alt=""
                  />
                  {{ m.name }}
                </div>
              </div>
            </div>
          </div>
        </Poptip>
        <Poptip
            placement="bottom-start"
        >
          <div class="map-operation-item">
            <i class="fas fa-search"></i> <span>{{ $t('x_location') }}</span>
            <div class="triangle-sm-fixed"></div>
          </div>
          <div
              class="pop-content"
              slot="content"
          >
            <div class="measurements">
              <div
                  class="mini-fn-item"
                  @click="locationSearchOpen"
              >
                <i class="fas fa-search"></i>
                <span>{{ $t('x_location_search') }}</span>
              </div>
            </div>
          </div>
        </Poptip>
        <Poptip
            placement="bottom-start"
        >
          <div class="map-operation-item">
            <i class="fas fa-cog"></i> <span>{{ $t('x_settings') }}</span>
            <div class="triangle-sm-fixed"></div>
          </div>
          <div
              class="pop-content"
              slot="content"
          >
            <div class="measurements">
              <div
                  class="mini-fn-item"
                  @click="userManagementOpen"
                  v-if="roleId<4"

              >
                <i class="fas fa-users"></i>
                <span>{{ $t('x_user_management') }}</span>
              </div>
              <div
                  class="mini-fn-item"
                  @click="systemLogOpen"
                  v-if="roleId<4"

              >
                <i class="fas fa-book"></i>
                <span>System Log</span>
              </div>
              <div
                  class="mini-fn-item"
                  @click="downloadUserManual"
              >
                <i class="fas fa-chalkboard-teacher"></i>
                <span><a href="/GISPlatform_User_Manual.pdf" download="User Manual">{{ $t('x_user_manual') }}</a></span>
              </div>
              <div
                  class="mini-fn-item"
                  @click="logOut"
              >
                <i class="fas fa-power-off"></i>
                <span>{{ $t('x_logout') }}</span>
              </div>
            </div>

          </div>
        </Poptip>

      </div>

      <!-- 地图容器 -->
      <Row
          type="flex"
          class="full-col aabb"
          id="baseViewerContainer"
      >
        <!-- 主地图容器 -->
        <div
            class="full-col full-col-no-overflow no-overflow"
            :style="{ width: mapSplitVal + '%' }"
            id="splitViewerContainer1"
        >
          <div id="templatePage">
            <!-- 2维地图 -->
            <div
                class="map-container"
                id="map2dContainer"
            >
              <div class="map-tools">
                <Tooltip
                    :content="$t('x_fly_home')"
                    placement="right"
                    transfer
                >
                  <Button
                      class="tool-menu-item"
                      @click="flyToHome('2D')"
                  >
                    <i class="fas fa-home"></i>
                  </Button>
                </Tooltip>
                <Tooltip
                    :content="$t('x_screen_cap')"
                    placement="right"
                    transfer
                >
                  <Button
                      class="tool-menu-item"
                      @click="clickScreenCapture2D"
                  >
                    <i class="fas fa-camera"></i>
                  </Button>
                </Tooltip>
                <Tooltip
                    :content="$t('x_full_screen')"
                    transfer
                    placement="right"
                >
                  <Button
                      class="tool-menu-item"
                      @click="fullscreenClick"
                  >
                    <i class="fas fa-expand"></i>
                  </Button>
                </Tooltip>
                <Tooltip
                    :content="$t('x_clear_operation')"
                    transfer
                    placement="right"
                >
                  <Button
                      class="tool-menu-item"
                      @click="clearAllAnalysis"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </Button>
                </Tooltip>
              </div>

              <div id="imgPop" v-show="showImgPop">
                <Row class="img-pop-header">
                  <Col span="23">
                    <span v-t="'x_media_info'"></span>
                  </Col>
                  <Col span="1">
                    <Button
                        class="btn-viewgroup-ghost"
                        @click="pmsSummaryClose"
                    >
                      <i class="fas fa-times"></i>
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col :span="4" v-for="(item) in allSelectPhotos" :key="item">
                    <div style="display: flex;justify-content: space-between">
                      <Checkbox v-model="item.compare" @on-change="setPhotosToView(item)"
                                :disabled="toComparedPhotos.length>=6 && !item.compare"></Checkbox>
                      <Button v-if="!item.displayAttr" @click="showItemAttribute(item)"
                              style="margin-right: 10px"><i
                          class="fas fa-arrow-down"></i></Button>
                      <Button v-if="item.displayAttr" @click="hideItemAttribute(item)"
                              style="margin-right: 10px"><i
                          class="fas fa-arrow-up"></i></Button>
                    </div>
                    <div style="height: 300px">
                      <img
                          :src="item.thumbnail? item.thumbnail+'?token='+VR3DUtil.getToken() : item.url+'?token='+VR3DUtil.getToken()"
                          width="260"
                          height="195"
                          @click="showFullImage(item)"
                      />
                      <table
                          class="table-selected-image"
                          border="1"
                          align="center"
                          v-show="item.displayAttr"
                      >
                        <tr>
                          <td>Fly Path</td>
                          <td>
                            {{ item.flyPlan }}
                          </td>
                        </tr>
                        <tr>
                          <td>ImgOrder</td>
                          <td>
                            {{ item.imgOrder }}
                          </td>
                        </tr>
                        <tr>
                          <td v-t="'x_date'"></td>
                          <td>
                            {{ item.publishTime }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t("x_upload_lng") }} (deg)</td>
                          <td>
                            {{ item.longitude.toFixed(3) }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t("x_upload_lat") }} (deg)</td>
                          <td>
                            {{ item.latitude.toFixed(3) }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t("x_northing") }}</td>
                          <td>
                            {{ item.northing.toFixed(3) }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t("x_easting") }}</td>
                          <td>
                            {{ item.easting.toFixed(3) }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t("x_upload_height") }} (m)</td>
                          <td>
                            {{ item.height }}
                          </td>
                        </tr>
                        <tr>
                          <td>Tilt</td>
                          <td>
                            {{ item.kappa }}
                          </td>
                        </tr>
                      </table>
                    </div>

                  </Col>
                </Row>
                <Button @click="compareSelectedPhotos" :disabled="toComparedPhotos.length<=1">Compare
                </Button>
              </div>

              <div id="videoPop">
                <Row class="img-pop-header">
                  <Col span="23">
                    <span v-t="'x_media_info'"></span>
                  </Col>
                  <Col span="1">
                    <Button
                        class="btn-viewgroup-ghost"
                        @click="pmsSummaryClose"
                    >
                      <i class="fas fa-times"></i>
                    </Button>
                  </Col>
                </Row>
                <video
                    controls
                    autoplay
                    id="videomedia"
                >
                  <source src type="video/mp4"/>
                </video>
              </div>
            </div>
            <div id="popup" class="ol-popup">
              <a href="#" id="popup-closer" class="ol-popup-closer"></a>
              <div id="popup-content"></div>
            </div>
          </div>
        </div>
      </Row>

    </div>

    <Modal v-model="isLocationSearchModal"
           width="auto"
           :styles="{top: 0}"
           footer-hide
           :mask="false"
           draggable
           :closable="false"
           class-name="fn-modal locationSearch-modal"
    >
      <div slot="header">
        <span class="modal-title">{{ $t('x_location_search') }}</span>
        <Button
            class="modal-close-btn"
            type="text"
            @click="isLocationSearchModal = false"
        ><i class="fas fa-times"></i></Button>
      </div>
      <Form
          :model="locationForm"
          label-position="top"
      >
        <FormItem>
          <div class="input-wrap-div">
            <Select
                v-model="selectedThatUrl"
                transfer
            >
              <Option value="All">All</Option>
              <Option
                  v-for="u in locatorUrls"
                  :value="u.url"
                  :key="u.name"
              >{{ u.name }}
              </Option>
            </Select>
            <AutoComplete
                v-model="locationForm.location"
                id="locationSearch"
                placement="bottom"
                @on-search="doLocationSearchDebounced"
                clearable
                transfer
                transfer-class-name="location-search-transfer"
                :placeholder="$t(selectedThatUrl == 'All' ? 'x_search_placeholder_location' : locatorUrls.find(i=>i.url == selectedThatUrl).placeholder)"
            >
              <div
                  class="demo-auto-complete-item"
                  v-for="(item, index) in locationSearchData"
                  :key="index"
              >
                <div
                    class="demo-auto-complete-group"
                    v-show="item.children.length > 0"
                >
                  <span style="font-weight: bold">{{ $t(item.title) }}</span>
                </div>
                <div class="auto-complete-scroll-item">
                  <Option
                      v-for="option in item.children"
                      :value="option.label"
                      :key="option.label"
                  >
                    <div @click="zoomToLocation(option)"> {{ option.label }}
                    </div>
                  </Option>
                </div>
              </div>
            </AutoComplete>
          </div>
          <Button
              style="margin-top:10px"
              type="primary"
              @click="doLocationSearch(null,true)"
          >{{ $t('x_search') }}
          </Button>
          <Button
              style="margin-top:10px"
              type="text"
              @click="canelSearchResult"
          >{{ $t('x_reset') }}
          </Button>
          <div v-show="isShowInList">
            <div
                class="demo-auto-complete-item"
                v-for="(item, index) in locationSearchData"
                :key="index"
            >
              <div
                  class="demo-auto-complete-group"
                  v-show="item.children.length > 0"
              >
                <span style="font-weight: bold;">{{ $t(item.title) }}</span>
              </div>
              <div class="auto-complete-scroll-item">
                <Option
                    v-for="option in item.children"
                    :value="option.label"
                    :key="option.label"
                >
                  <div @click="zoomToLocation(option)"> {{ option.label }}
                  </div>
                </Option>
              </div>
            </div>
          </div>
        </FormItem>
      </Form>
    </Modal>

    <Modal v-model="isFlyPlanShown"
           class-name="flyplan-modal"
           :mask-closable="false"
           :mask="true"
           sticky
           closable
           footer-hide
           :transfer="false"
           @on-visible-change="isDownloadPanelShown=false;useremailaddress=''"
           :width="flyPlanModalWidth"
    >
      <Select v-model="selectedStage" style="width:200px" @on-change="iniFlyPlan">
        <Option :value="5" :key="5">Year 2024</Option>
        <Option :value="1" :key="1">Year 2023</Option>
        <Option :value="2" :key="2">Year 2022</Option>
        <Option :value="4" :key="4">Year 2021</Option>
        <Option :value="3" :key="3">Year 2020</Option>
      </Select>
      <Table
          :data="flyPlanTableDate"
          :columns="flyPlanTableColumns"
          :max-height="600"
          :no-data-text="$t('x_flyplan_no_flyplan')">
        <template #action="{ row }">
          <Poptip trigger="hover" transfer content="View On the Map">
            <Button type="primary" size="small" style="margin-right: 5px"
                    @click="zoomToLayer(row.displayLayerId)"><i class="fas fa-eye"></i></Button>
          </Poptip>
          <Poptip trigger="hover" placement="right" transfer>
            <Button type="primary" size="small"><i class="fas fa-download"></i></Button>
            <template #content>
              <ButtonGroup vertical>
                <Button size="small"
                        @click="isDownloadPanelShown=true;flyPlanToDownload={type:'plan',layerId:row.flyPlanId,layerName:row.name}">
                  All Flight Data
                </Button>
                <Button size="small"
                        @click="isDownloadPanelShown=true;flyPlanToDownload={type:'trajectory',layerId:row.flyPlanId,layerName:row.name+'_trajectory'}">
                  Flight Paths Only
                </Button>
              </ButtonGroup>
            </template>
          </Poptip>
        </template>
        <template slot-scope="{ row,column,index }" :slot="item.slot"
                  v-for="(item ) in flyPlanTableColumns.slice(4)">
          <div style="display: flex">
            <strong>{{ row[column.slot] }}</strong>
            <Poptip trigger="hover" placement="right" v-if="row[column.slot]" style="margin-left: 4px">
              <i class="fas fa-download"></i>
              <template #content>
                <ButtonGroup vertical>
                  <Button v-for="(item2,index2) in row[column.slot].split(',')" size="small" :key="'D' + index2"
                          @click="isDownloadPanelShown=true;flyPlanToDownload={type:'layer',layerId:row[column.slot+'_mediaLayers'][index2],layerName:row[column.slot+'_mediaNames'][index2]}">
                    {{ 'Download： ' + row[column.slot + '_mediaNames'][index2] }}
                  </Button>
                  <Button v-for="(item2,index2) in row[column.slot].split(',')" size="small" :key="'T' + index2"
                          @click="isDownloadPanelShown=true;flyPlanToDownload={type:'trajectorylayer',layerId:row[column.slot+'_trajectoryLayers'][index2],layerName:row[column.slot+'_mediaNames'][index2]+'_trajectory'}">
                    {{
                      'Download： ' + row[column.slot + '_mediaNames'][index2] + '_trajectory only'
                    }}
                  </Button>
                </ButtonGroup>
              </template>
            </Poptip>
          </div>
        </template>
      </Table>
      <Divider v-if="isDownloadPanelShown"/>
      <div v-if="isDownloadPanelShown">
        <span>{{ flyPlanToDownload.layerName }}</span>
        <Input v-model="useremailaddress" placeholder="Please input your email address">
          <template #append>
            <span>@pland.gov.hk</span>
          </template>
        </Input>
        <Button @click="downloadFlyPath" :disabled="useremailaddress===''" :loading="packageFiles">Download
        </Button>
      </div>
    </Modal>

    <Modal v-model="displayPhotoModal"
           class-name="displayPhoto-modal"
           fullscreen
           footer-hide
           @on-visible-change="destroyImgViewer"
    >
      <div class="photo-preview-col">
        <div id="photoOnDisplay"/>
        <div class="photoinfo-panel">
          <div>
            <strong>{{ selectedPhotoInfo.flyPlan }}</strong>
          </div>
          <br/>
          <div>
            <strong>Order:{{ selectedPhotoInfo.imgOrder }}</strong>
          </div>
          <br/>
          <div>
            <Input border placeholder="Please input your email" v-model="useremailaddress">
              <template #append>
                <span>@pland.gov.hk</span>
              </template>
            </Input>
          </div>
          <br/>
          <div style="width: 200px">
            <Button :disabled="useremailaddress===''" @click="downloadImage" type="primary">Download
            </Button>
          </div>
        </div>
      </div>
    </Modal>

    <Modal v-model="isUserManagementShown"
           class-name="user-modal"
           footer-hide
           :width="1500">
      <div id="users">
        <div class="layout-nav">
          <div class="search-bar">
            <div>
              <Button type="primary" @click="createNewUser">
                {{ $t("x_create_new_user") }}
              </Button>
            </div>
          </div>
        </div>
        <div class="layout-body">
          <div class="body-content">
            <div class="content-padding">
              <Table
                  v-if="users.list.length>0"
                  context-menu
                  class="members-table"
                  ref="userTable"
                  :row-class-name="rowClassName"
                  :data="users.list"
                  :columns="userColumns"
                  :highlight-row="true"
                  :loading="showLoading"
              >
                <template slot-scope="{ row }" slot="username">
                  {{ row.username }}
                </template>
                <template slot-scope="{ row }" slot="roleId">
                  <Tag color="red" v-if="row.roleId === 3">{{ $t("x_role_admin") }}</Tag>
                  <Tag color="blue" v-if="row.roleId === 4">{{ $t("x_role_user") }}</Tag>
                </template>
                <template slot-scope="{ row }" slot="status">
                  <Tag color="red" v-if="row.status === 0">{{ $t("x_user_disable") }}</Tag>
                  <Tag color="blue" v-if="row.status === 1">{{ $t("x_user_normal") }}</Tag>
                </template>
                <template slot-scope="{ row }" slot="shared">
                  <Tooltip :content="$t('x_update')" placement="top">
                    <Icon @click="toUpdateUser(row)" type="ios-create-outline"/>
                  </Tooltip>
                  <Tooltip :content="$t('x_delete')" placement="top">
                    <Icon @click="toDeleteUser(row)" type="ios-trash-outline"/>
                  </Tooltip>
                </template>
              </Table>
            </div>

            <!--            <div>-->
            <!--              <Page v-if="users.list.length>0"-->
            <!--                    transfer-->
            <!--                    :total="users.total"-->
            <!--                    :current="users.page"-->
            <!--                    :page-size="users.pageSize"-->
            <!--                    @on-change="userPageChange"></Page>-->
            <!--            </div>-->

            <div class="nodata-tip" v-if="!users.list.length && !showLoading">
              <i class="fas fa-file-search"></i>
              <div>{{ $t('x_nodata') }}</div>
            </div>
            <div class="data-loading-tip" v-show="!users.list.length && showLoading">
              <Spin>
                <Icon type="ios-loading" size=18 class="data-spin-load"></Icon>
                <div>{{ $t('x_data_loading') }}</div>
              </Spin>
            </div>
          </div>
        </div>
        <Modal
            v-model="users.addUserModal"
            width="480"
            :mask-closable="false"
            @on-visible-change="userModalVisibaleChange"
        >
          <div slot="header">
            {{ userEditForm.userId ? $t("x_update") : $t("x_create_new_user") }}
          </div>
          <div>
            <br/>
            <Form
                ref="userForm"
                :model="userEditForm"
                :label-width="120"
                label-position="left"
                class="left-align-form"
                :rules="userRules"
            >
              <FormItem :label="$t('x_signup_name')" prop="username">
                <Input v-if="!userEditForm.userId" v-model="userEditForm.username" placeholder/>
                <span v-if="userEditForm.userId">{{ userEditForm.username }}</span>
              </FormItem>
              <FormItem
                  :label="$t('x_password')"
                  prop="password"
                  v-if="!userEditForm.userId"
              >
                <Input
                    type="password"
                    v-model="userEditForm.password"
                    placeholder
                />
                <!-- <Row v-show="isPwdRobustTest">
                  <Col span="16">
                    <Slider
                      v-model="pwdScore"
                      class="slider-pwd-score"
                      :min="0"
                      :max="5"
                      show-stops
                      show-tip="never"
                      disabled
                    ></Slider>
                  </Col>
                  <Col span="2">&nbsp;</Col>
                  <Col span="6">
                    <b class="b-pwd-test-result">{{ robustTestResult }}</b>
                  </Col>
                </Row> -->
              </FormItem>
              <FormItem
                  :label="$t('x_signup_repwd')"
                  prop="repassword"
                  v-if="!userEditForm.userId"
              >
                <Input
                    type="password"
                    v-model="userEditForm.repassword"
                    placeholder
                />
              </FormItem>
              <FormItem :label="$t('x_signup_email')" prop="email">
                <Input v-if="!userEditForm.userId" v-model="userEditForm.email" placeholder/>
                <span v-if="userEditForm.userId">{{ userEditForm.email }}</span>
              </FormItem>
              <FormItem :label="$t('x_phone')" prop="mobile">
                <Input v-if="!userEditForm.userId" v-model="userEditForm.mobile" placeholder/>
                <span v-if="userEditForm.userId">{{ userEditForm.mobile }}</span>
              </FormItem>
              <FormItem :label="$t('x_role')" prop="roleId">
                <Select v-model="userEditForm.roleId">
                  <Option
                      v-for="role in roleList"
                      :value="role.roleId"
                      :key="role.roleId"
                  >{{ role.roleName }}
                  </Option
                  >
                </Select>
              </FormItem>
              <FormItem
                  :label="$t('x_status')"
                  prop="status"
                  v-if="userEditForm.userId"
              >
                <RadioGroup v-model="userEditForm.status">
                  <Radio :label="0">{{ $t("x_user_disable") }}</Radio>
                  <Radio :label="1">{{ $t("x_user_normal") }}</Radio>
                </RadioGroup>
              </FormItem>
            </Form>
          </div>
          <div slot="footer">
            <Button type="text" @click="users.addUserModal = false">{{
                $t("x_cancel")
              }}
            </Button>
            <Button
                type="primary"
                :disabled="showLoading"
                :loading="showLoading"
                @click="submitUserForm"
            >
              <span>{{ $t("x_submit") }}</span>
            </Button>
          </div>
        </Modal>
      </div>
    </Modal>

    <Modal v-model="isSysLogShown"
           width="85"
           class="model-fix-height"
           footer-hide>
      <div><Input v-model="sysLogFilter" @on-change="reloadLog">
        <template #prepend>
          <span>Filter:</span>
        </template>
      </Input></div>
      <Table :columns="logColumns" :data="logs.list" max-height="660">

      </Table>
      <div style="display: flex;justify-content: space-between">
        <Page
            v-if="logs.list.length>0"
            transfer
            :total="logs.total"
            :current="logs.page"
            :page-size="logs.pageSize"
            @on-change="logPageChange"
        />
        <Button @click="exportLogs">Export</Button>
      </div>
    </Modal>

    <PhotoCompareViewer :allSelectPhotos="photoToCompare" :isShow="isPhotoCompareShow"
                        @on-close="isPhotoCompareShow=false"/>
  </div>
</template>
<script>
import _ from "lodash";
import "iv-viewer/dist/iv-viewer.css";
import "echarts-gl";
import Cookies from "js-cookie";
import saveAs from "file-saver";
import MapOL from "../../libs/MapOL.js";
import VR3DUtil from "../../libs/VR3D/Util/Util.js";
import Service from "../../libs/service.js";
import Vue from "vue";
import screenfull from 'screenfull';
import * as olProj from 'ol/proj';

import LocationSearchDrawOL from "../../libs/VR3D/OpenLayers/Search/LocationSearchDrawOL.js"
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import PhotoCompareViewer from "@/views/component/PhotoCompareViewer.vue";
import OpenSeadragon from 'openseadragon'

Vue.use(VueViewer)
export default {
  components: {
    PhotoCompareViewer,
  },
  data() {
    var validatePassword = (rule, value, callback) => {
      if (!this.userEditForm.userId && !/\S/.test(value)) {
        callback(new Error(this.$t("x_empty_err")));
      } else {
        callback();
      }
    };
    var validateComfirmPassword = (rule, value, callback) => {
      if (!this.userEditForm.userId && !/\S/.test(value)) {
        callback(new Error(this.$t("x_empty_err")));
      } else if (this.userEditForm.password != value) {
        callback(new Error(this.$t("x_signup_repwd_error")));
      } else {
        callback();
      }
    };
    return {
      roleId: 5,
      displayPhotoModal: false,
      imageCorper: null,
      isSysLogShown: false,
      sysLogFilter: '',
      logs: {
        total: 0,
        page: 1,
        pageSize: 20,
        list: []
      },
      logColumns: [
        {
          title: 'User',
          key: 'username',
          width: 200
        },
        {
          title: 'Operation',
          key: 'operation',
          width: 400
        },
        {
          title: 'Params',
          key: 'params',
          width: 600
        }
        ,
        {
          title: 'IP Address',
          key: 'ip',
          width: 150
        },
        {
          title: 'Time',
          key: 'createDate',
          width: 200
        }
      ],
      isUserManagementShown: false,
      users: {
        keywords: "",
        total: 0,
        page: 1,
        pageSize: 100,
        list: [],
        originresult: [],
        checked: [],
        selected: {},
        addUserModal: false,
        inviteUserModal: false,
        inviteUserTabVal: 'link',
        allList: [],
        mixedGroup: [],
      },
      userColumns: [
        {
          title: this.$t("x_username"),
          slot: "username",
          sortable: true,
        },
        {
          title: this.$t("x_signup_email"),
          key: "email",
          sortable: true,
          className: "folder-info-column",
        },
        {
          title: this.$t("x_role"),
          slot: "roleId",
          sortable: true,
          className: "folder-info-column",
          filters: [
            {
              label: this.$t("x_role_admin"),
              value: 3,
            },
            {
              label: this.$t("x_role_user"),
              value: 4,
            }
          ],
          filterMultiple: false,
          filterMethod(value, row) {
            return row.roleId == value;
          },
        },
        {
          title: this.$t("x_status"),
          slot: "status",
          className: "folder-info-column",
          filters: [
            {
              label: this.$t("x_user_disable"),
              value: 0,
            },
            {
              label: this.$t("x_user_normal"),
              value: 1,
            }
          ],
          filterMultiple: false,
          filterMethod(value, row) {
            return row.status == value;
          },
        },
        {
          title: this.$t("x_action"),
          slot: "shared",
          width: "150",
          className: "folder-icon-column",
          align: "center",
        },
      ],
      user_template: {
        username: "",
        password: "",
        repassword: "",
        email: "",
        mobile: "",
        roleId: 4,
        status: 1,
      },
      userEditForm: {},
      userRules: {
        username: [
          {
            required: true,
            message: this.$t("x_empty_err"),
            trigger: "blur",
          },
        ],
        password: [{validator: validatePassword, trigger: "blur"}],
        repassword: [{validator: validateComfirmPassword, trigger: "blur"}],
        email: [
          {
            required: true,
            message: this.$t("x_empty_err"),
            trigger: "blur",
          },
          {
            type: "email",
            message: this.$t("x_email_invlidate"),
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: this.$t("x_empty_err"),
            trigger: "blur",
          }
        ],
        roleId: [
          {
            required: true,
            type: "number",
            message: this.$t("x_empty_err"),
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            type: "number",
            message: this.$t("x_empty_err"),
            trigger: "blur",
          },
        ],
      },
      roleList: [
        {
          roleId: 3,
          roleName: this.$t("x_role_admin"),
        },

        {
          roleId: 4,
          roleName: this.$t("x_role_user"),
        },

      ],
      isPhotoCompareShow: false,
      showImgPop: false,
      showVideoPop: false,
      allSelectPhotos: [],
      photoToCompare: [],
      toComparedPhotos: [],
      flyPlanModalWidth: 80,
      selectedStage: 5,
      isDownloadPanelShown: false,
      useremailaddress: '',
      packageFiles: false,
      flyPlanToDownload: {},
      isFlyPlanShown: false,
      flyPlanLayers: [],
      flyPlanDisplay: '',
      flyPlanTableDate: [],
      flyPlanTableColumns: [],
      iniflyPlanTableColumns: [
        {
          title: 'Location',
          key: 'name',
          width: 120,
          fixed: 'left',
          sortable: true,
          filters: [],
          filterMethod(value, row) {
            return row.name.indexOf(value) > -1;
          }
        },
        {
          title: 'District',
          key: 'district',
          width: 120,
          fixed: 'left',
          sortable: true,
          filters: [],
          filterMethod(value, row) {
            return row.district.indexOf(value) > -1;
          }
        },
        {
          title: 'Completed Flights',
          key: 'comFlights',
          width: 120,
          fixed: 'left'
        },
        {
          title: 'Zoom/Download',
          slot: 'action',
          width: 140,
          align: 'center',
          fixed: 'left'
        }
      ],
      craftList: [],
      mtrInfo: {},
      cctvIfo: {},
      realtimeLayers: [
        {
          name: "Traffic Snapshot",
          id: 2,
          type: "layer",
          show: false,
          info: {
            billboard: "/images/Camera3.png",
            category: "IOT CATEGORY",
            collectDate: null,
            companyId: 42,
            createTime: "2022-02-22 11:00:18",
            defaultLayer: null,
            displayTitle: null,
            elevation: null,
            epsg: null,
            flattenRegion: null,
            geometry: null,
            height: null,
            isAutoOpen: true,
            isOpen: null,
            isPrivate: false,
            latitude: null,
            layerId: 5119,
            longitude: null,
            order: null,
            parameter: null,
            potreeUrl: null,
            queryDataset: null,
            scriptAnim: null,
            size: null,
            source: null,
            src: null,
            structure: null,
            title: "TD Traffic Snapshot",
            type: "IOT",
            url: "/dav/device/list?categoryId=48",
            url_2d: null,
            userId: 737,
            username: "Jason_Yang",
          }
        },
        {
          name: "MTR Arrival Time",
          id: 1,
          type: "layer",
          show: false,
          info: {
            billboard: "/images/mtr.png",
            category: "IOT CATEGORY",
            collectDate: null,
            companyId: 42,
            createTime: "2022-02-22 18:14:16",
            defaultLayer: null,
            displayTitle: null,
            elevation: null,
            epsg: null,
            flattenRegion: null,
            geometry: null,
            height: null,
            isAutoOpen: true,
            isOpen: null,
            isPrivate: false,
            latitude: null,
            layerId: 5120,
            longitude: null,
            order: null,
            parameter: null,
            potreeUrl: null,
            queryDataset: null,
            scriptAnim: null,
            size: null,
            source: null,
            src: null,
            structure: null,
            title: "MTR Station",
            type: "IOT",
            url: "/dav/device/list?categoryId=49",
            url_2d: null,
            userId: 737,
            username: "Jason_Yang",
          }
        }
      ],
      mobileEventsModal: false,
      selectMobileEvent: null,
      reportList: [],
      mobileEvents: [],
      displayEventsIcon: false,
      currentWorkspaceUserId: -1,
      transparencyVals: [
          {
        name: '0',
        label: '0%'
      },
        {
          name: '10',
          label: '10%'
        }, {
          name: '20',
          label: '20%'
        }, {
          name: '30',
          label: '30%'
        },
        {
          name: '40',
          label: '40%'
        }, {
          name: '50',
          label: '50%'
        }, {
          name: '60',
          label: '60%'
        },
        {
          name: '70',
          label: '70%'
        }, {
          name: '80',
          label: '80%'
        }, {
          name: '90',
          label: '90%'
        }, {
          name: '100',
          label: '100%'
        }
      ],
      lastMapRenderQuality: 2,
      mapRenderQuality: 2,
      mapSplitValSlider: 50,
      showLayerSetting: false,
      clipPlaneModal: false,
      clipBoxModal: false,
      showCutSectionViewer: false,
      showSkylineViewer: false,
      skyviewModal: false,
      shadowModal: false,
      terrainModifyModal: false,
      tidalModal: false,
      volumeModal: false,
      identifyModal: false,
      drawingModal: false,

      baseLayer2DItems: [],
      baseLayer3DItems: [],
      baseLayer2Didx: null,
      baseLayer3Didxs: [],
      baseLayer2Didxs: [],
      baseLayer2DidxsDD: [],
      baseLayer2DidxDD: null,

      onlyItems: [],
      onlyItems3D: [],

      opacityMarks: {
        0: "0%",
        50: "50%",
        100: "100%",
      },

      identifyRuning: false,

      isfullscreen: false,
      ajaxCalling: false,
      sysLoading: false,
      spinShow: false,
      spinShowNoProgress: false,
      qualitySpinShow: false,
      showLoading: false,
      processPercent: 0,
      is2D: false,
      listOpen: false,
      fun: {
        design: {
          on: true,
        },
        analysis: {
          on: false,
        },
      },
      pop: {
        mode: {
          on: false,
        },
        realtimedata: {
          on: false,
        },
        layer: {
          on: false,
        },
        basemap: {
          on: false,
        },
        location: {
          on: false,
        },
        measure: {
          on: false,
        },
        analysis: {
          on: false,
        },
        drawing: {
          on: false,
        },
        setting: {
          on: false,
        },
      },
      isWorkspace: false,
      potreeLayers: [],
      baseurl: "",
      Map: null,
      curSelectedLayerNode: {},
      onMapSplitMode: false,
      mapSplitVal: 100,
      splitMode: "split",

      layerTreeData: [],
      layerTreeDataSplit: [],
      lastLayerTreeSelected: [],
      sceneMode: "3D",
      viewMode: "optionFly",
      homeC3: [],
      homeOrientation: [],
      isRecordingScene: false,

      isPanoView: false,
      panoViewOpacity: 100,


      isPointCloud: false,
      ptCloudURL: undefined,
      skyviewRatio: 0,

      isLockPosition: false,
      isLock2D: false,
      streetViewOpacity: 100,
      terrainTransparency: 100,
      isTerrainTransparency: false,
      terrainTransparencySlide: {},

      objUploadUrl: "",
      objUploadUserUrl: "",
      annoUploadUrl: "",

      screenRecorder: undefined,
      analysisOn: {
        flood: false,
        shadow: false,
        flypath: false,
        volumePre: false,
        volume: false,
        clip_plane: false,
        clip_box: false,
        terrain_modify: false,
        clip_plane_vertical: false,
        clip_plane_horizontal: false,
      },

      freeDrawingColumns: [
        {
          title: "Draw Entity",
          slot: "title",
          width: 105,
        },
        {
          title: "Height",
          slot: "height",
          width: 100,
        },
        {
          title: "Area",
          slot: "area",
          width: 100,
        },
        {
          title: "Color",
          slot: "color",
          width: 70,
        },
        {
          title: "Visible to All",
          slot: "isPrivate",
          width: 100,
          align: "center",
        },
        {
          title: "",
          slot: "action",
          fixed: "right",
          align: "center",
          width: 100,
        },
      ],
      freeDrawingData: [],


      sceneTime: 57600,

      play4DIntv: undefined,
      user_token: undefined,

      uploadAcceptType: ".json,.geojson",
      selectedCoordSys: {
        epsg: "4326",
        name: "",
        proj4: "+proj=longlat +datum=WGS84 +no_defs",
      },


      annoVisible: true,
      isShowTableResult: false,
      identifyTableColumns: [
        {
          title: "a",
          key: "b",
        },
        {
          title: "c",
          key: "d",
          type: "html",
        },
      ],
      identifyTableEditableColumns: [
        {
          title: "a",
          key: "b",
        },
        {
          title: "c",
          slot: "d",
        },
      ],
      identifyTableData: [],
      identifyResult: [],
      isIdentifyResultEditable: false,
      identifyShpName: undefined,


      isVolumeResultShow: false,
      volumeForm: {
        type: 0,
        height: 0,
        isQuick: true,
      },
      volumeTypeList: [
        {
          k: 0,
          v: this.$t("x_ms_volume_base_low"),
        },
        {
          k: 1,
          v: this.$t("x_ms_volume_base_high"),
        },
        {
          k: 2,
          v: this.$t("x_ms_volume_base_ave"),
        },
        {
          k: 4,
          v: this.$t("x_ms_volume_base_triang"),
        },
        {
          k: 3,
          v: this.$t("x_ms_volume_base_man"),
        },
      ],
      volumePreForm: {
        type: 0,
        kmlUser: "",
        proj4: "",
        regionMode: 1,
      },
      volumeDefineTypeList: [
        {
          k: 0,
          v: "Draw on 3D",
        },
      ],
      isVolumeBaseSet: true,

      uploadActionURL: `${Baseurl}/VR3D/DataProcessor/kmlToGeoJSON`,
      volumeResult: {},

      corsysListVol: [],
      volumeRegionTypeList: [
        {
          k: 1,
          b: 1,
          v: this.$t("x_vm_region_ring"),
        },
        {
          k: 0,
          b: 0,
          v: this.$t("x_vm_spot_tin"),
        },
      ],


      clipPlaneForm: {
        isShowController: true,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
        heading: 0,
        tilt: 0,


        layerList: [],
      },
      floodForm: {

        level: 10,
      },
      terrainModifyForm: {
        level: 10,
      },
      shadowForm: {
        date: new Date(),
        startTime: 8,
        endTime: 18,
        height_above: 30,
      },
      shadowTimeList: [

        {
          k: 7,
          v: "7:00",
        },
        {
          k: 8,
          v: "8:00",
        },
        {
          k: 9,
          v: "9:00",
        },
        {
          k: 10,
          v: "10:00",
        },
        {
          k: 11,
          v: "11:00",
        },
        {
          k: 12,
          v: "12:00",
        },
        {
          k: 13,
          v: "13:00",
        },
        {
          k: 14,
          v: "14:00",
        },
        {
          k: 15,
          v: "15:00",
        },
        {
          k: 16,
          v: "16:00",
        },
        {
          k: 17,
          v: "17:00",
        },
        {
          k: 18,
          v: "18:00",
        },
        {
          k: 19,
          v: "19:00",
        },
      ],
      isShadowStat: true,
      flyPathForm: {

        speed: 120,
        saveAsVideo: false,
        videoName: "",
      },
      clipBoxModeList: [
        {
          k: "clip_behind_all_plane",
          v: "Clip within box",
        },
        {
          k: "clip_behind_any_plane",
          v: "Clip outside box",
        },
      ],
      clipBoxForm: {
        mode: "clip_behind_any_plane",
        width: 25,
        height: 25,
        extrude: 10,
        heading: 0,
        isShowBox: true,
        translateX: 0,
        translateY: 0,
        translateZ: 5,
      },
      cutSectionChartTypeList: [
        {
          k: 2,
          v: "2D",
        },
        {
          k: 3,
          v: "3D",
        },
      ],
      currentCutSectionChartType: 2,
      profileModalLoading: false,
      shpColor: {
        stroke: "#00FF00",
        strokePEdge: "#0000FF",
        fill: "#FFFF00",
      },
      selectedPhotoInfo: {
        flyPlan: '',
        imgOrder: ''
      },


      layerSettingsForm: {
        ptCloudStyle: 0,
      },
      ptCloudStyleList: [{
        k: 0,
        v: "RGB",
      },
        {
          k: 1,
          v: "HEIGHT",
        },
        {
          k: 2,
          v: "INTENSITY",
        },
        {
          k: 3,
          v: "CLASSIFICATION",
        },
      ],
      ptFilterShow: false,
      ptClassShow: Array(19).fill(true),
      ptClassList: [{
        id: 0,
        checked: true,
        type: 'Never Classified'
      }, {
        id: 1,
        checked: true,
        type: 'Unassined'
      }, {
        id: 2,
        checked: true,
        type: 'Ground'
      }, {
        id: 3,
        checked: true,
        type: 'Low Vegetation'
      }, {
        id: 4,
        checked: true,
        type: 'Medium Vegetation'
      }, {
        id: 5,
        checked: true,
        type: 'High Vegetation'
      }, {
        id: 6,
        checked: true,
        type: 'Building'
      }, {
        id: 7,
        checked: true,
        type: 'Low point (noise)'
      }, {
        id: 8,
        checked: true,
        type: 'Key point'
      }, {
        id: 9,
        checked: true,
        type: 'Water'
      }, {
        id: 10,
        checked: true,
        type: 'Rail'
      }, {
        id: 11,
        checked: true,
        type: 'Road Surface'
      }, {
        id: 12,
        checked: true,
        type: 'Overlap'
      }, {
        id: 13,
        checked: true,
        type: 'Wire-Guard'
      }, {
        id: 14,
        checked: true,
        type: 'Wire-Conductor'
      }, {
        id: 15,
        checked: true,
        type: 'Transmission Tower'
      }, {
        id: 16,
        checked: true,
        type: 'Wire-Structure'
      }, {
        id: 17,
        checked: true,
        type: 'Bridge Deck'
      }, {
        id: 18,
        checked: true,
        type: 'High Noise'
      }],


      vr3dGanttElasticBus: new Vue(),
      identifySelectedType: 'clickQuery',
      bufferRadius: 100,
      bufferRadiusUnit: 1,

      identifySelectedType2: 'clickQuery',
      identifyBtn: true,

      rightPanelTitle: '',
      isAddFlyPath: false,
      flyPathData: [],
      flyPathColumn: [
        {
          title: this.$t("x_name"),
          slot: "title",
        },
        {
          title: "",
          slot: "action",
          fixed: "right",
          align: "center",
          width: 60,
        },
      ],
      addFlyPathForm: {
        title: "",
        description: "",
      },
      flyThroughType: false,
      funstate: -1,
      isViewgroupOwner: false,
      playIntvIdx: undefined,
      groupCurrentViewIdx: 0,
      isAddViewportInGroup: false,
      viewGroupPortList: [],
      viewgroupTimeline: {
        marks: {},
        val: 0,
        data: {},
        min: 0,
        max: 0,
        step: 1,
        selectedViewport: {},
      },
      isShowViewGroupPortList: false,
      isFlyPathNotEditing: false,
      flyStopData: [],
      flyStopColumn: [
        {
          title: this.$t("x_name"),
          slot: "title",
        },
        {
          title: this.$t("x_fly_speed") + '(km/h)',
          slot: "speed",
        },
        {
          title: this.$t("x_duration") + '(s)',
          slot: "duration",
        },
        {
          title: "",
          slot: "action",
          fixed: "right",
          align: "center",
          width: 60,
        },
      ],
      currentFlyPathId: undefined,
      currentFlyPathName: undefined,
      onFn: "",
      split1: 0.25,
      flyThroughHeight: 0,
      shareWorkspaceModal: false,
      workspaceId: null,
      workspaceShareCode: '',
      workspaceShareLink: '',
      linkLoading: false,

      validityForm: {
        days: 7,
        power: -1,
        password: '',
        qrcode: '',
      },
      wsPwdForm: {
        pwd: ''
      },
      ruleValidity: {
        days: [

          {required: true, type: "number", message: this.$t('x_empty_err'), trigger: 'change'}
        ],
        power: [
          {required: true, type: "number", message: this.$t('x_empty_err'), trigger: 'change'}
        ],
        pwd: [
          {required: true, message: this.$t('x_empty_err'), trigger: 'blur'}
        ],
      },
      validity: null,
      isWsNeedPwd: null,
      companyId: null,
      isCompany: false,
      wSetPwdForm: {
        password: '',
      },
      isWsSetPwdModal: false,
      companyPower: null,

      bestPathGuide: [],
      bestPathList: [{
        name: 'x_bestpath_sp',
        id: 0,
        coord: [],
      },
        {
          name: 'x_bestpath_dp',
          id: 1,
          coord: [],
        }],
      bestPathAddress: "",
      bestPathIsWalking: false,
      bestPathIsDriving: true,
      bestPathRouteJson: {},
      uTurnIDList: [],
      bestPathTime: 0,
      bestPathCount: 10,
      bestPathSpeed: 30,
      bestPathMeasure: 0,
      bestPathLoading: false,
      bestpathBtn: true,

      serviceAreaPoint: null,
      isServiceArea: false,
      serviceAreaLoading: false,
      serviceAreaIsWalking: false,
      serviceAreaTimeValue: 1,
      serviceAreaDistanceValue: 500,
      serviceAreaDriveSpeedValue: 30,
      serviceAreaWalkSpeedValue: 5,

      identifyCont: false,
      identifyQuery: false,
      identifyTitle: '',

      isShowInList: false,
      isLocationSearchModal: false,
      locationForm: {
        location: "",
        panel: "",
      },
      locationSearchData: [],

      split2: 0,
      minSplit2: 0,
      visitor: null,
      collabDrawingBus: new Vue(),
      pid: undefined,



      currDraw: {
        id: undefined,
        title: "Draw",
        collaborate: true
      },
      newDraw: {
        title: "New draw",
        description: "",
        collaborate: true
      },
      tmpNameDraw: "",
      selectSketchProjName: "",
      selectSketchProjId: undefined,
      wsDrawList: [],
      wsDrawListColumns: [
        {
          title: "Draw Title",
          slot: "title",
          align: "left",
        },
        {
          title: "Collaborate",
          slot: "collaborate",
          align: "left",
          width: 80,
        },





        {
          title: "",
          slot: "action",
          fixed: "right",
          align: "center",
          width: 120,
        },
      ],

      isAddDraw: false,
      drawModal: false,
      drawListModal: false,
      bestpathModal: false,
      weatherINFO: {},
      weatherModal: false,

      isFullscreen: false,
      screenLock: false,

      homeCenter: [114.1392695, 22.3525587],
      homeZoomLevel: 11.5,
      mobileCheck: false,

      leftLayerVal: '',
      oldLayerTreeData: [],

      rightLayerVal: '',
      oldLayerTreeDataSplit: [],
      mobileMobile: {
        min: false,
        moving: false
      },
      isGeolocation: false,

      showFunc: {},
      selectedThatUrl: 'All',
      locatorUrls: [
        {
          name: 'Address',
          placeholder: 'Input Addresses',
          url: 'https://api.hkmapservice.gov.hk/ags/gc/loc/address'
        }, {
          name: 'Building',
          placeholder: 'Building Name',
          url: 'https://api.hkmapservice.gov.hk/ags/gc/ib1000/buildings/building'
        }, {
          name: 'Building Licence',
          placeholder: 'e.g BL 1/11',
          url: 'https://api.hkmapservice.gov.hk/ags/gc/ic1000/buildinglicence'
        },
        {
          name: 'Geo Community',
          placeholder: 'Input community Name',
          url: 'https://api.hkmapservice.gov.hk/ags/gc/loc/geocomm'
        }, {
          name: 'GeodeticHControl',
          placeholder: 'e.g. 2001.001',
          url: 'https://api.hkmapservice.gov.hk/ags/gc/sc/GeodeticHControl'
        }, {
          name: 'GeodeticVControl',
          placeholder: 'e.g. 200001',
          url: 'https://api.hkmapservice.gov.hk/ags/gc/sc/GeodeticVControl'
        }, {
          name: 'GLA',
          placeholder: 'e.g gla-dn 110',
          url: 'https://api.hkmapservice.gov.hk/ags/gc/ic1000/gla'
        }, {
          name: 'Local Control',
          placeholder: 'e.g. CM1/DN1819B',
          url: 'https://api.hkmapservice.gov.hk/ags/gc/sc/localcontrol'
        }, {
          name: 'Lot',
          placeholder: 'e.g SSTL 112',
          url: 'https://api.hkmapservice.gov.hk/ags/gc/ic1000/lot'
        }, {

          name: 'Lot CSUID',
          placeholder: 'e.g. 2570 158 B',
          url: 'https://api.hkmapservice.gov.hk/ags/gc/loc/lotcsuid'
        }, {
          name: 'Lot PRN',
          placeholder: 'e.g. D1690035',
          url: 'https://api.hkmapservice.gov.hk/ags/gc/loc/prn'
        }, {
          name: 'Place Point',
          placeholder: 'e.g Tsim Sha Tsui',
          url: 'https://api.hkmapservice.gov.hk/ags/gc/ib5000/poi/placepoint'
        }, {
          name: 'POI',
          placeholder: 'e.g Tsim Sha Tsui',
          url: 'https://api.hkmapservice.gov.hk/ags/gc/ib5000/poi/poipoint'
        }, {
          name: 'Road',
          placeholder: 'e.g. Nathan Road',
          url: 'https://api.hkmapservice.gov.hk/ags/gc/ib1000/transportation/streetcentrelines'
        }, {
          name: 'Site',
          placeholder: 'e.g. TAIKOO, WONG TAI SIN TEMPLE',
          url: 'https://api.hkmapservice.gov.hk/ags/gc/ib1000/buildings/site'
        }, {
          name: 'Street Intersection',
          placeholder: 'e.g. Nathan Road',
          url: 'https://api.hkmapservice.gov.hk/ags/gc/loc/streetintersection'
        }, {
          name: 'SubSite',
          placeholder: 'e.g. Wong Tai Sin',
          url: 'https://api.hkmapservice.gov.hk/ags/gc/ib1000/buildings/subsite'
        }, {
          name: 'Utility Point',
          placeholder: 'e.g. LPO-123,FWH-123,LPO-EBE/D/123 ',
          url: 'https://api.hkmapservice.gov.hk/ags/gc/ib1000/utilities/utilitypoint'
        },
        {
          name: 'VGS',
          placeholder: 'e.g DLO/KW070',
          url: 'https://api.hkmapservice.gov.hk/ags/gc/ls/vacantgovsite'
        }
      ],
      geolocationCoord: [],
      hosWaitShow: false,
      hosWaitData: null,
      isLogin: false,
      isWalkMode: false,
      isTrafficSpeedOn: false,
    };
  },
  directives: {
    drag(el) {
      let oDiv = el;
      let self = this;

      document.onselectstart = function () {
        return false;
      };
      oDiv.onmousedown = function (e) {

        let disX = e.clientX - oDiv.offsetLeft;
        let disY = e.clientY - oDiv.offsetTop;
        document.onmousemove = function (e) {

          let l = e.clientX - disX;
          let t = e.clientY - disY;

          oDiv.style.left = l + "px";
          oDiv.style.top = t + "px";
        };
        document.onmouseup = function (e) {
          document.onmousemove = null;
          document.onmouseup = null;
        };

        return false;
      };
    },
  },
  computed: {
    VR3DUtil() {
      return VR3DUtil
    },
    processColor() {
      let color = "#19be6b";
      if (this.processPercent == 100) {
        color = "#19be6b";
      }
      return color;
    },
    mapObject() {
      return this.Map;
    },
    mapMode() {
      return this.is2D ? "2D" : "3D";
    },
  },
  methods: {
    init() {
      Window.vui = this


      function addStyleHeader(url) {
        let link = document.createElement("link");
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = url;

        document.head.appendChild(link);
      }

      addStyleHeader('./openlayers/ol.css');
      addStyleHeader('./openlayers/iclient-ol.min.css');

      this.companyId = Number(Cookies.get("companyId"));
      if (this.gup("isCompany")) {
        this.isCompany = Boolean(this.gup("isCompany"));
      }

      this.userId = Cookies.get("userId");

      this.interpQueryString();

      let that = this;

      let projectID =
          that.$route.query.pid == null ? NaN : Number(that.$route.query.pid);


      function start3DViewer() {
        Service.request(
            "get",
            `/sys/defaultLayer/list`,
            undefined,
            function (dt) {





              let arr23D = that.configBaseLayerInfo(dt.data);
              window.map2D.initMap();

              that.preSetBaseMapUI(arr23D[0]);
              that.iniFlyPlan()
            },
            function (err) {
              that.$Message.error({
                content: that.$t("x_internal_server_err"),
              });
            }
        );
      }

      this.user_token = VR3DUtil.getToken();


      window.map2D = new MapOL('map2dContainer');

      this.baseurl = window.Baseurl;

      this.roleId = Cookies.get("access");

      start3DViewer();
    },

    iniFlyPlan() {
      var that = this
      Service.request("get",
          "/sys/flyplan/listLayers",
          {
            stage: that.selectedStage
          }, function (dt) {
            var flyplanlayers = dt.data
            var iniLayerIds = []
            that.flyPlanTableDate.splice(0)
            that.flyPlanTableColumns = _.cloneDeep(that.iniflyPlanTableColumns)
            if (flyplanlayers.length > 0) {
              var minFlyDate = new Date(_.minBy(flyplanlayers, function (o) {
                return o.collectDate;
              }).collectDate)
              var minYear = minFlyDate.getFullYear()
              var minMonth = minFlyDate.getMonth() + 1
              var maxFlyDate = new Date(_.maxBy(flyplanlayers, function (o) {
                return o.collectDate;
              }).collectDate)
              var maxYear = maxFlyDate.getFullYear()
              var maxMonth = maxFlyDate.getMonth() + 1

              var compareMonths = (maxYear - minYear) * 12 + maxMonth - minMonth

              var iMonth = minMonth - 1;
              var iYear = minYear

              var months = ["Jan", "Feb", "Mar", "Apr",
                "May", "Jun", "Jul", "Aug",
                "Sep", "Oct", "Nov", "Dec"];

              for (var i = 0; i <= compareMonths; i++) {
                iMonth++;
                if (iMonth > 12) {
                  iYear++;
                  iMonth -= 12;
                }
                var titleDate = months[iMonth - 1] + ' ' + iYear
                var keyDate = iYear + '-' + ('0' + iMonth).slice(-2)
                that.flyPlanTableColumns.push({
                  title: titleDate,
                  slot: keyDate,
                  width: 100
                })
              }

              var grouped = _.groupBy(flyplanlayers, 'flyPlanId')
              var flyPlanIds = _.keys(grouped)
              let reg = /[0-9]+/g;

              for (i = 0; i < flyPlanIds.length; i++) {
                var layers = grouped[flyPlanIds[i]]
                var layerObject = {}
                layerObject.flyPlanId = flyPlanIds[i]
                layers = _.sortBy(layers, 'collectDate')
                for (var j = 0; j < layers.length; j++) {
                  if (j === 0) {
                    layerObject.name = layers[j].name
                    layerObject.district = layers[j].district
                    layerObject.comFlights = layers.length + '/' + layers[j].planFlights
                    layerObject.displayLayerId = layers[j].mediaLayerId
                    layerObject.trajectoryLayerId = layers[j].trajectoryLayerId
                    iniLayerIds.push(layers[j].mediaLayerId)
                    iniLayerIds.push(layers[j].trajectoryLayerId)

                    _.find(that.flyPlanTableColumns, function (x) {
                      return x.title === 'Location'
                    }).filters.push({
                      label: layers[j].name.replace(reg, "").replace(/^\s*|\s*$/g, ""),
                      value: layers[j].name.replace(reg, "").replace(/^\s*|\s*$/g, "")
                    })
                    var districtFilter = _.find(that.flyPlanTableColumns, function (x) {
                      return x.title === 'District'
                    }).filters
                    if (_.findIndex(districtFilter, function (o) {
                      return o.value === layers[j].district
                    }) < 0) {
                      districtFilter.push({
                        label: layers[j].district,
                        value: layers[j].district
                      })
                    }
                  }
                  var layerMonth = layers[j].collectDate.substr(0, 7)
                  var layerMonthStr = months[Number(layers[j].collectDate.substr(5, 2)) - 1]
                  var layerDate = layers[j].collectDate.substr(8, 2)
                  if (!Object.prototype.hasOwnProperty.call(layerObject, layerMonth)) {
                    layerObject[layerMonth] = Number(layerDate).toString() + ' ' + layerMonthStr
                    layerObject[layerMonth + '_mediaLayers'] = [layers[j].mediaLayerId]
                    layerObject[layerMonth + '_trajectoryLayers'] = [layers[j].trajectoryLayerId]
                    layerObject[layerMonth + '_mediaNames'] = [layers[j].name + ': ' + layers[j].collectDate.substr(0, 10)]
                  } else {
                    layerObject[layerMonth] += ',' + Number(layerDate).toString() + ' ' + layerMonthStr
                    layerObject[layerMonth + '_mediaLayers'].push(layers[j].mediaLayerId)
                    layerObject[layerMonth + '_trajectoryLayers'].push(layers[j].trajectoryLayerId)
                    layerObject[layerMonth + '_mediaNames'].push(layers[j].name + ': ' + layers[j].collectDate.substr(0, 10))
                  }
                }
                that.flyPlanTableDate.push(layerObject)
              }
            }

            var locationFilter = _.find(that.flyPlanTableColumns, function (x) {
              return x.title === 'Location'
            }).filters;
            _.find(that.flyPlanTableColumns, function (x) {
              return x.title === 'Location'
            }).filters = _.uniqBy(locationFilter, function (e) {
              return e.label;
            });

            that.loadMediaAndTrajectory(iniLayerIds)
          }, function (err) {
            console.error(err)
          })
    },

    loadMediaAndTrajectory(layerIds) {

      var that = this
      if (layerIds.length > 0) {

        Service.request(
            "post",
            `/sys/layer/listByIds`,
            layerIds,
            function (dt) {
              console.log("loadLayers")
              dt = dt.data;
              that.flyPlanLayers = dt
              var layerCtrlArr = []
              var footprints = []
              for (var i = 0; i < dt.length; i++) {

                var layerItem = dt[i]
                layerCtrlArr.push({
                  icon: "fas fa-layer-group",
                  id: layerItem.layerId,
                  text: layerItem.title,
                  k: layerItem.layerId,
                  selected: true,
                  type: layerItem.type,
                  category: layerItem.category,
                  opacity: 100,
                  url: layerItem.url,
                  geometry: layerItem.geometry,
                  elevation: layerItem.elevation,
                  case: layerItem.parameter,
                  queryDataset: JSON.parse(layerItem.queryDataset),
                  flatten_region: JSON.parse(layerItem.flattenRegion),
                  billboard: layerItem.billboard,
                });
                if (layerItem.geometry != null) {
                  footprints.push({
                    geometry: layerItem.geometry,
                    name: layerItem.title.split('_')[0],
                    layerId: layerItem.layerId
                  })
                }
              }

              footprints = _.uniqBy(footprints, function (x) {
                return x.name
              })
              window.map2D.layerCtrl(layerCtrlArr, window.mapOL)
              window.map2D.addFootPrint(footprints)
            }
        );

      } else {
        var layerCtrlArr = []
        window.map2D.layerCtrl(layerCtrlArr, window.mapOL)
      }
    },

    downloadFlyPath() {
      var that = this
      this.packageFiles = true

      var data = {
        type: that.flyPlanToDownload.type,
        layerId: that.flyPlanToDownload.layerId.toString(),
        layerName: that.flyPlanToDownload.layerName,
        emailaddress: that.useremailaddress.endsWith("@raspect.ai")? that.useremailaddress: that.useremailaddress + '@pland.gov.hk'
      }
      console.log(data)

      Service.request(
          "post",
          `/sys/flyplan/download`,
          data,
          function (dt) {
            console.log(dt)
            that.$Notice.success({
              title: '',
              duration: 0,
              onClose: function () {
                that.packageFiles = false;
                that.$Notice.destroy()
              },
              render: h => {
                return h('span', [
                  'The system is packing the photos/fly-paths for you to download and will send you a link for downloading the files to your email address when it is completed.'
                ])
              }
            })
          },
          function (err) {
            console.error(err)
            that.$Notice.error({
              title: '',
              onClose: function () {
                that.packageFiles = false;
                that.$Notice.destroy()
              },
              render: h => {
                return h('span', [
                  err.message ? err.message : 'Error when packing the photos, please contact the system admin.'
                ])
              }
            })
          }
      );
    },

    createNewUser() {
      this.userEditForm = _.cloneDeep(this.user_template);
      this.users.addUserModal = true;
    },
    resetUserForm() {
      this.$refs["userForm"].resetFields();
    },
    toDeleteUser(row) {

      this.userDeleteHandle(row);
    },
    userDeleteHandle(row) {
      var that = this;
      var userIds = [row.userId];

      var userNames = ["'" + row.username + "'"];

      this.$nextTick(() => {
        that.showLoading = true;
      });

      this.$Modal.confirm({
        title: this.$t("x_delete"),
        content: `${this.$t("x_comfirm_remove", [
          this.$t("x_user"),
          userNames.join(","),
        ])}`,
        loading: false,
        onOk: () => {
          Service.request(
              "delete",
              "/sys/user/delete",
              userIds,
              function (dt) {
                that.showLoading = false;
                that.$Modal.remove();
                that.getUserList();
              },
              function (err) {
                that.showLoading = false;
                that.$Modal.remove();
              }
          );
        },
        onCancel: () => {
          that.showLoading = false;
        },
      });
    },
    toUpdateUser(row) {
      var that = this;
      Service.request("get", `/sys/user/info/${row.userId}`, "", function (dt) {
        that.users.addUserModal = true;
        that.userEditForm = dt.data;
      });
    },
    submitUserForm() {
      var that = this;
      this.$nextTick(() => {
        that.showLoading = true;
      });

      this.$refs["userForm"].validate((valid) => {
        if ((valid && that.userEditForm.userId) || !that.userEditForm.userId) {
          if (that.pwdScore < 1 && !that.userEditForm.userId) {
            that.$Message.error(that.$t("x_pwd_weak_no_submit"));
            that.$nextTick(() => {
              that.showLoading = false;
            });
            return;
          }

          var data = {
            userId: that.userEditForm.userId || undefined,
            username: that.userEditForm.username,
            password: that.userEditForm.password,
            email: that.userEditForm.email,
            mobile: that.userEditForm.mobile,
            status: that.userEditForm.status,
            roleId: that.userEditForm.roleId,
            companyId: parseInt(Cookies.get("companyId")),
          };

          Service.request(
              "post",
              `/sys/user/${!this.userEditForm.userId ? "save" : "update"}`,
              data,
              function (dt) {
                that.showLoading = true;
                that.$nextTick(() => {
                  that.showLoading = false;
                  that.resetUserForm();
                  that.users.addUserModal = false;
                });
                that.getUserList();
              },
              function (err) {
                that.$nextTick(() => {
                  that.showLoading = false;
                });
              }
          );
        } else {
          that.$nextTick(() => {
            that.showLoading = false;
          });
        }
      });
    },
    userModalVisibaleChange(open) {
      if (!open) {
        this.resetUserForm();
        this.userEditForm = _.cloneDeep(this.user_template);
      }
    },
    rowClassName(row, index) {
      return "folder-row";
    },

    getSelectedPhotos(mediaId) {
      var that = this
      Service.request("get",
          `/sys/media/getrelatedphotos`,
          {mediaId: mediaId},
          function (dt) {
            that.toComparedPhotos = []
            console.log(dt)
            that.allSelectPhotos = _.sortBy(dt.data, 'publishTime')
            that.showImgPop = true
          },
          function (err) {
          }
      )
    },

    getSelectedVideos(mediaId) {
      var that = this

      function bindingFunction(id, url) {
        document.getElementById(id).onclick = function () {
          that.showVideo(url)
        }
      }

      const content = document.getElementById('popup-content');
      content.innerHTML = ''
      this.selectedPhotoInfo.type = 'video'

      Service.request("get",
          `/sys/media/getrelatedphotos`,
          {mediaId: mediaId},
          function (dt) {
            dt = dt.data
            for (var i = 0; i < dt.length; i++) {
              content.innerHTML += '<p id=\"' + dt[i].mediaId + '\"><code>' + dt[i].flyPlan + '</code></p>'
            }
            for (var j = 0; j < dt.length; j++) {
              bindingFunction(dt[j].mediaId, dt[j].url)
            }

          },
          function (err) {
          }
      )
    },

    showVideo(url) {
      let domVideoPop = document.getElementById("videoPop");
      domVideoPop.style.display = "block";
      let videoDom = document.getElementById("videomedia");
      let videoSourceDOM = videoDom.getElementsByTagName("source")[0];
      videoSourceDOM.setAttribute("src", window.baseHost + url + '?token=' + VR3DUtil.getToken());
      videoDom.load();
    },

    destroyImgViewer(visi) {
      if (!visi) {
        this.imageCorper.destroy()
        this.useremailaddress = ''
      }
    },

    showFullImage(item) {
      this.selectedPhotoInfo = item
      this.displayPhotoModal = true

      const viewer = OpenSeadragon({
        id: "photoOnDisplay",
        preventViewport: true,
        showNavigationControl: false,
        defaultZoomLevel: 0.8,
        tileSources: {
          type: 'image',
          url: window.baseHost + item.url + '?token=' + VR3DUtil.getToken()
        }
      });


      this.imageCorper = viewer
    },

    downloadImage() {
      var that = this;
      var data = {}
      data.emailaddress = this.useremailaddress.endsWith("@raspect.ai")? this.useremailaddress: this.useremailaddress + '@pland.gov.hk'
      data.mediaIds = [this.selectedPhotoInfo.mediaId.toString()]
      data.flyPlanName = this.selectedPhotoInfo.flyPlan + ': ' + this.selectedPhotoInfo.imgOrder
      Service.request("post", "/sys/media/download",
          data, function (dt) {
            that.$Notice.success({
              title: '',
              duration: 0,
              onClose: function () {
                that.packageFiles = false;
                that.$Notice.destroy()
              },
              render: h => {
                return h('span', [
                  'The system is packing the photos/fly-paths for you to download and will send you a link for downloading the files to your email address when it is completed.'
                ])
              }
            })
          }, function (err) {
            that.$Notice.error({
              title: '',
              onClose: function () {
                that.packageFiles = false;
                that.$Notice.destroy()
              },
              render: h => {
                return h('span', [
                  err.message ? err.message : 'Error when packing the photos, please contact the system admin.'
                ])
              }
            })
          })
    },

    setPhotosToView(item) {
      console.log(item)
      if (item.compare && !_.find(this.toComparedPhotos, function (o) {
        return o.mediaId === item.mediaId;
      })) {
        this.toComparedPhotos.push({
          imgOrder: item.imgOrder,
          flyPlan: item.flyPlan,
          title: item.title,
          datetime: item.publishTime,
          url: window.baseHost + item.url + '?token=' + VR3DUtil.getToken(),
          mediaId: item.mediaId,
          thumbnail: window.baseHost + item.thumbnail + '?token=' + VR3DUtil.getToken()
        });
      } else if (!item.compare && _.find(this.toComparedPhotos, function (o) {
        return o.mediaId === item.mediaId;
      })) {
        _.remove(this.toComparedPhotos, function (o) {
          return o.mediaId === item.mediaId;
        });
      }
    },

    showItemAttribute(item) {
      this.$set(item, 'displayAttr', true)
    },
    hideItemAttribute(item) {
      this.$set(item, 'displayAttr', false)
    },
    compareSelectedPhotos() {
      this.photoToCompare = _.sortBy(_.cloneDeep(this.toComparedPhotos), 'datetime')
      this.isPhotoCompareShow = true;
    },

    gup(name, url) {
      if (!url) url = location.href;

      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      var regexS = "[\\?&]" + name + "=([^&#]*)";
      var regex = new RegExp(regexS);
      var results = regex.exec(url);
      return results == null ? NaN : results[1];
    },

    fullscreenClick() {
      this.pop.mode.on = false;

      const element = document.getElementById('appViewer');

      if (!screenfull.isEnabled) {
        this.$message({
          message: '浏览器不允许全屏',
        })
        return false;
      }
      screenfull.toggle(element);
      this.isFullscreen = true;
    },

    interpQueryString() {
      function gup(name, url) {
        if (!url) url = location.href;
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regexS = "[\\?&]" + name + "=([^&#]*)";
        var regex = new RegExp(regexS);
        var results = regex.exec(url);
        regex = undefined;
        return results == null ? null : results[1];
      }

      let encryQueryString = this.$route.query.p ? this.$route.query.p : "";


      let queryString = decodeURI(escape(atob(encryQueryString)));
      queryString = queryString.replace(/%3D/g, "=").replace(/%26/g, "&");

      let dummyUrl = `url?${queryString}`;


      if (!this.$route.query.pid) {
        this.$route.query.pid = gup("pid", dummyUrl);
      }
      if (!this.$route.query.title) {
        this.$route.query.title = gup("title", dummyUrl);
      }
      this.isWorkspace = this.$route.query.isWorkspace = Boolean(
          gup("workspace", dummyUrl)
      );
    },

    configBaseLayerInfo(data) {
      let arr2D = [];
      let arr3D = [];

      let map2d = window.map2D;
      map2d.regBasemap(data);

      this.baseLayer2DItems = {};

      for (let i = 0; i < data.length; i++) {
        let layerItem = data[i];
        layerItem.case = layerItem.parameter;

        let layerIcon,
            isLayer2D = false;
        switch (layerItem.basemapType) {
          case "SATELLITE":
            layerIcon = layerItem.icon || "./images/basemap/satellite.jpg";
            isLayer2D = true;
            break;
          case "MAP":
            layerIcon = layerItem.icon || "./images/basemap/map.jpg";
            isLayer2D = true;
            break;
        }
        if (isLayer2D) {
          if (layerItem.isAutoOpen) arr2D.push(layerItem.layerId);
          this.baseLayer2DItems[layerItem.layerId] = {
            name: layerItem.title,
            value: layerItem.layerId,
            image: layerIcon,
            isOnly: layerItem.isOnly === 't',
          };

          if (layerItem.isOnly === 't') this.onlyItems.push(layerItem.title);
        }
      }

      return [arr2D, arr3D];
    },
    preSetBaseMapUI(arr2D) {
      var that = this;
      arr2D.forEach((dd) => {
        let target = that.baseLayer2DItems[dd];
        that.setBaseMapUI(target.value, target.isOnly);
      });
    },
    setBaseMapUI(basemapID, isOnly) {
      console.log("setbasemap")
      console.log(basemapID, isOnly)
      window.map2D.setBaseMap(basemapID)
    },
    setBaseMapUIDD(basemapID, isOnly) {
      if (isNaN(Number(basemapID))) basemapID = JSON.parse(basemapID);
      var that = this;

      function removeLabel(labelName) {
        let labelBaseMap = window.mapOL.getLayers().getArray().find(i => i.name == labelName);
        if (labelBaseMap) {
          labelBaseMap.dispose();
          window.mapOL.removeLayer(labelBaseMap);
        }
      }

      function removeOnly(name) {
        for (let i = 0; i < that.onlyItems.length; i++) {
          if (name != that.onlyItems[i]) {
            let target = window.mapOL.getLayers().getArray().find(k => k.name == that.onlyItems[i]);
            if (target) {
              target.dispose();
              window.mapOL.removeLayer(target);
            }
          }
        }
      }

      if (!isOnly && this.baseLayer2DItems[basemapID]) {
        isOnly = this.baseLayer2DItems[basemapID].isOnly || false;
      }

      let mapid = Number(basemapID);
      this.pop.basemap.on = false;

      if (this.baseLayer2DidxDD == mapid || this.baseLayer2DidxsDD.includes(mapid)) {
        return;
      } else {
        if (!isOnly)
          this.baseLayer2DidxsDD.push(mapid);
        else {
          this.baseLayer2DidxDD = mapid;
          if (mapid != 107 && mapid != 106) {
            removeLabel('landsD_basemap_label');
          } else {
            removeLabel('OZP_Label')
          }

        }
      }
      window.map2D.setBaseMap(mapid);
    },

    flyToHome(val) {
      if (val == '2D') {
        window.mapOL.getView().animate({
          center: olProj.transform([this.homeCenter[0], this.homeCenter[1]], 'EPSG:4326', 'EPSG:3857'),
          zoom: this.homeZoomLevel,
          duration: 500
        });
      }
    },


    clearAllAnalysis() {
      if (window.searchSymbol2D) {
        window.searchSymbol2D.clear();
      }
    },


    pmsSummaryClose() {
      window.mapOL.selectedPhoto = null;

      this.showImgPop = false
      let domVideoPop = document.getElementById("videoPop");
      domVideoPop.style.display = "none";
      let domVideoPlayer = document.getElementById("videomedia");
      if (domVideoPlayer) {
        domVideoPlayer.pause()
      }
    },


    doLocationZoomToCallback(data) {
      data = JSON.parse(data)
      if (data.candidates) {
        for (var intI = 0; intI < data.candidates.length; intI++) {
          var lat = data.candidates[intI].location.y;
          var lon = data.candidates[intI].location.x;

          this.zoomToAndLabel(lon, lat, 0);
          break
        }
      }
    },
    doLocationSearchCallback(data, group) {
      data = JSON.parse(data)
      let that = this;
      let results = []
      if (data.suggestions) {
        for (var i = 0; i < data.suggestions.length; i++) {



          results.push({
            label: data.suggestions[i].text,
            magicKey: data.suggestions[i].magicKey
          })
        }
      }
      let isPush = true;
      that.locationSearchData.forEach((item) => {
        if (item.title == group) {
          isPush = false
          item.children = results
        }
      })
      if (isPush == true) {
        let obj = {};
        obj.title = group;
        obj.children = results;
        that.locationSearchData.push(obj)
      }
    },
    doLocationSearchDebounced: _.debounce(function (val, val2) {
      this.doLocationSearch(val, val2);
    }, 300),
    doLocationSearch(text, _isShow = false) {
      this.isShowInList = _isShow;
      if (!window.locationSearchUrl || !window.locationSearchUrlList) {
        window.locationSearchApikey = "584b2fa686f14ba283874318b3b8d6b0";
        window.locationSearchUrlList = [
          ["Building", "https://api.hkmapservice.gov.hk/ags/gc/ib1000/buildings/building"],
          ["Address", "https://api.hkmapservice.gov.hk/ags/gc/loc/address"],
          ["Places", "https://api.hkmapservice.gov.hk/ags/gc/ib5000/poi/placepoint"],
          ["Lot", "https://api.hkmapservice.gov.hk/ags/gc/ic1000/lot"]]
      }
      window.locationSearchUrlList = [];
      if (this.selectedThatUrl == 'All') {
        this.locatorUrls.forEach(item => {
          window.locationSearchUrlList.push([item.name, item.url]);
        })
      } else {
        let target = this.locatorUrls.find(ob => ob.url == this.selectedThatUrl)
        window.locationSearchUrlList.push([target.name, target.url]);
      }

      let apikey = window.locationSearchApikey;
      let that = this;

      that.locationSearchData = [];
      window.locationSearchUrlList.forEach((item) => {
        let request = that.locationForm.location;

        let suggestURL = item[1] + "/suggest?f=json"
        suggestURL += "&maxSuggestions=20"
        suggestURL += "&text=" + encodeURI(request.trim())
        suggestURL += "&key=" + apikey;
        that.httpGetAsyncLocation(suggestURL, that.doLocationSearchCallback, item[0]);
      })
    },
    zoomToLocation(currentSelectionItem) {
      let that = this;
      let url = "https://api.hkmapservice.gov.hk/ags/gc/composite";
      let apikey = window.locationSearchApikey;
      var magicKey = currentSelectionItem.magicKey;
      var FindAddressURL = "";
      if (magicKey != "") {
        that.locationForm.location = currentSelectionItem.label;
        FindAddressURL = url + '/findAddressCandidates?f=json&outFields=*';
        FindAddressURL += '&maxLocations=20';
        FindAddressURL += '&outFields=*';
        FindAddressURL += '&outSR=4326';
        FindAddressURL += '&magicKey=';
        FindAddressURL += magicKey + '&key=' + apikey;
        that.httpGetAsyncLocation(FindAddressURL, that.doLocationZoomToCallback)
      }
    },
    canelSearchResult(currentSelectionItem) {
      this.locationForm.location = "";
      this.isShowInList = false;
      if (window.searchSymbol2D) {
        window.searchSymbol2D.clear();
      }
    },
    httpGetAsyncLocation(theUrl, callback, group = "") {
      var xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = function () {
        if (xmlHttp.readyState == 4 && xmlHttp.status == 200)
          callback(xmlHttp.responseText, group);
      }
      xmlHttp.open("GET", theUrl, true);
      xmlHttp.send(null);
    },
    zoomToAndLabel(x, y, z) {

      if (window.searchSymbol2D) {
        window.searchSymbol2D.clear();
        window.searchSymbol2D = undefined;
      }
      window.searchSymbol2D = new LocationSearchDrawOL(window.mapOL, {});
      setTimeout(() => {
        let xy = olProj.transform([x, y], 'EPSG:4326', 'EPSG:3857');
        window.searchSymbol2D.addSymbol(xy[0], xy[1], z);
        window.mapOL.getView().fit(window.searchSymbol2D._features[0].getGeometry().getExtent(), {minResolution: 0.3})
      }, 50)

    },
    locationSearchOpen() {
      var theNavBar = document.getElementsByClassName('map-operations')[0].getBoundingClientRect();
      var theBar = document.getElementsByClassName('locationSearch-modal')[0].getElementsByClassName('ivu-modal-content')[0];

      theBar.style.top = theNavBar.bottom + 'px';
      theBar.style.left = theNavBar.left + 'px';
      theBar.style.width = theNavBar.width + 'px';
      this.canelSearchResult();
      setTimeout(() => {
        this.isLocationSearchModal = !this.isLocationSearchModal;
      }, 50);
    },

    systemLogOpen() {
      this.isSysLogShown = true
      var that = this
      var data = null
      if (this.sysLogFilter !== '') {
        data = {
          key: this.sysLogFilter
        }
      }
      Service.request("get", "/sys/log/page?page=1&limit=20&sidx=id&order=desc",
          data, function (dt) {
            console.log(dt)
            that.logs.list = dt.page.list
            that.logs.page = dt.page.currPage
            that.logs.total = dt.page.totalCount
          }, function (err) {
            console.error(err)
          })
    },

    logPageChange(page) {
      console.log(page)
      var data = null
      if (this.sysLogFilter !== '') {
        data = {
          key: this.sysLogFilter
        }
      }
      var that = this
      Service.request("get", "/sys/log/page?page=" + page + "&limit=20&sidx=id&order=desc",
          data, function (dt) {
            console.log(dt)
            that.logs.list = dt.page.list
            that.logs.page = dt.page.currPage
            that.logs.total = dt.page.totalCount
          }, function (err) {
            console.error(err)
          })
    },

    reloadLog() {
      var that = this
      var data = null
      if (this.sysLogFilter !== '') {
        data = {
          key: this.sysLogFilter
        }
      }
      Service.request("get", "/sys/log/page?page=1&limit=20&sidx=id&order=desc",
          data, function (dt) {
            console.log(dt)
            that.logs.list = dt.page.list
            that.logs.page = dt.page.currPage
            that.logs.total = dt.page.totalCount
          }, function (err) {
            console.error(err)
          })
    },

    exportLogs() {
      var data = null
      if (this.sysLogFilter !== '') {
        data = {
          key: this.sysLogFilter
        }
      }
      Service.request(
          "get",
          "/sys/log/download",
          data,
          function (dt) {
            if (dt.code === 0) {
              var url = dt.data
              const a = document.createElement("a");
              a.style = "display: none";
              a.target = "_blank";
              document.body.appendChild(a);
              a.href = url
              a.download = "system log.pdf";
              a.click();
            }
          },
          function (err) {
          }
      );
    },

    userManagementOpen() {
      var that = this
      var data = {
        page: this.users.page,
        limit: this.users.pageSize,
        sidx: this.users.sidx ? this.users.sidx : "user_id",
        order: this.users.order ? this.users.order : "desc",
      };

      if (
          _.isString(this.users.keywords) &&
          !_.isEmpty(this.users.keywords)
      ) {
        data.username = this.users.keywords;
      }
      this.getUserList(data)

      this.isUserManagementShown = true
    },

    getUserList(data) {
      var that = this
      Service.request(
          "get",
          "/sys/user/page",
          data,
          function (dt) {
            let f = dt.page.list;
            that.showLoading = false;

            that.users.total = dt.page.totalCount;
            that.users.page = dt.page.currPage
            that.users.list = f;
          },
          function (err) {
            that.showLoading = false;
          }
      )
    },
    downloadUserManual() {

    },
    logOut() {
      var that = this;
      Service.request("post", "/sys/logout", "", function (dt) {
        if (that.$store) {
          that.$store.commit("logout", this);
        }
        window.localStorage.isUnLogin = undefined;
        Cookies.remove("sdms_token");
        delete window.localStorage.isUnLogin;

        that.$router.push({
          name: "login",
        });
      });
    },
    showFlyPlanPanel() {
      this.isFlyPlanShown = !this.isFlyPlanShown
      if (this.isFlyPlanShown) {
        this.showImgPop = false
        let domVideoPop = document.getElementById("videoPop");
        domVideoPop.style.display = "none";
        let domVideoPlayer = document.getElementById("videomedia");
        if (domVideoPlayer) {
          domVideoPlayer.pause()
        }
      }

    },

    zoomToLayer(layerId) {
      var layerItem = _.find(this.flyPlanLayers, function (x) {
        return x.layerId == layerId
      })

      if (layerItem && layerItem.geometry) {
        this.isFlyPlanShown = false
        window.map2D.zoomToGeometry(layerItem.geometry, window.mapOL)
      }
    },

    clickScreenCapture2D(isSave = true) {
      let finishCapture = this.$Message.loading({
        content: this.$t('Generating screen capture'),
        duration: 0
      });
      if (window.mapOL) {
          let map = window.mapOL;
          map.once('rendercomplete', function () {
            var mapCanvas = document.createElement('canvas');
            var size = map.getSize();
            mapCanvas.width = size[0];
            mapCanvas.height = size[1];
            mapCanvas.crossOrigin = null;
            var mapContext = mapCanvas.getContext('2d');
            Array.prototype.forEach.call(
                document.querySelectorAll('.ol-layer canvas'),
                function (canvas) {
                  if (canvas.width > 0) {
                    var opacity = canvas.parentNode.style.opacity;
                    mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                    var transform = canvas.style.transform;

                    var matrix = transform
                        .match(/^matrix\(([^\(]*)\)$/)[1]
                        .split(',')
                        .map(Number);

                    CanvasRenderingContext2D.prototype.setTransform.apply(
                        mapContext,
                        matrix
                    );
                    mapContext.drawImage(canvas, 0, 0);
                  }
                }
            );


            if (navigator.msSaveBlob) {

              if (isSave) {
                window.temBlob = mapCanvas.msToBlob()
                navigator.msSaveBlob(window.temBlob, 'map.png');
              }
            } else {
              mapCanvas.toBlob(function (blob) {
                window.temBlob = blob
                if (isSave) {
                  saveAs(window.temBlob, 'screenShot.png');
                }

              });
            }
            finishCapture();
          });
          map.renderSync();
        }
    },

  },
  watch: {
    $route(to) {
      console.log(to);
    },
  },
  mounted() {
    this.init()
  },
};
</script>