import OpenLayersFunctions from '../OpenLayersFunctions.js';

// ---------------------------- OpenLayers libs --------------------------------
import {
  GeoJSON
} from 'ol/format';
import {
  Draw
} from 'ol/interaction';
import {
  Circle as StyleCircle, 
  Fill as StyleFill, 
  Stroke as StyleStroke,
  Style
} from 'ol/style';
// ------------------------ End of OpenLayers libs -----------------------------

class DrawOL {

  // drawtype can be "Point" "Polygon" "LineString" "Circle" "Text"
  constructor(map, options, drawtype = "Circle") {
    if (!options)
      options = {};
      
    this.options = options;
    this.map = map;
    
    this.drawtype = drawtype; // drawing type, can be "Point" "Polygon" "LineString" "Circle" "Text"
    // default style for all the drawing types
    

    this.style = new Style({
      fill: new StyleFill({
        color: this.options.fillColor? this.options.fillColor :'rgba(91, 80, 201, 0.5)',
      }),
      stroke: new StyleStroke({
        color: this.options.strokeColor? this.options.strokeColor :'rgba(255, 0, 98, 1)',
        width: 3
        // width: 50 / ol.map.getView().getResolution()
      }),
      image: new StyleCircle({
        radius: 5,
        fill: new StyleFill({
          color: this.options.fillColor? this.options.fillColor :'rgba(255, 0, 98, 1)',
        }),
        stroke: new StyleStroke({
          color: this.options.strokeColor? this.options.strokeColor :'rgba(255, 0, 98, 1)',
          width: 2
          // width: 50 / ol.map.getView().getResolution()
        }),
      }),
    }); //this.styles[this.drawtype];


    this._baseHeight = 0; // remaing attributes for 3D
    this.height = 0;

    // finish drawing Events
    this._onFinished = undefined;
    
    this.vectorLayer = OpenLayersFunctions.getVectorLayer(this.map);
    this.vectorLayer.setStyle(this.style);
    
    this._visible = true;
    this._isadded = false;
    this.features =[];
    
    if (!this.source) {
      this.source = this.vectorLayer ? this.vectorLayer.getSource() : undefined;
    }
  }
  
  get visible () { return this._visible; }
  set visible (val) {
    if(val == undefined)
      val = !this._visible;
      
    this._visible = val;
    if(val)
      this._feature.setStyle(this.style);
    else
      this._feature.setStyle(new Style());
  }
  
  flyTo () {
    this.map.getView().fit(this._feature.getGeometry().getExtent());
  }
  
  // initializing acoording to the drawtype
  init() {

    if (!this.source) {
      this.source = this.vectorLayer ? this.vectorLayer.getSource() : undefined;
      //this.vectorLayer = new ol.layer.Vector({source: this.source,style: this.style,});
      //this.map.addLayer(this.vectorLayer);
    }
    
    let that = this;
    if (this.drawtype == "Text") {
      this.style = this.styles["Text"]
      this.draw = new Draw.Draw({
        source: that.source,
        type: "Point",
        style: that.style,
      });
    } else {
      this.draw = new Draw.Draw({
        source: that.source,
        type: that.drawtype,
        style: that.style,
      });
    }
   
  }

  // calculate length
  getLength(_vectorFeature) {
    if (_vectorFeature) {
      return _vectorFeature.getGeometry().getLength()
    }
    return 0;
  }

  // calculate area
  getArea(_vectorFeature) {
    if (this._feature) {
      return this._feature.getGeometry().getArea();
    }
    return 0;
  }

  // covert the current draw object's feature to geojson
  toGeoJSON(ouput) {
    if (!this.GeoJSON) {
      this.GeoJSON = new GeoJSON();
    }

    var geoJsonObj = this.GeoJSON.writeFeatureObject(this._feature);
    
    // Customize: Only preserve the geometry part
    geoJsonObj = geoJsonObj.geometry;
    // if(geoJsonObj.type == 'Point') {
      // geoJsonObj.coordinates[2] = 0;
    // }
    // else if (geoJsonObj.type == 'LineString') {
      // for(let i=0; i<geoJsonObj.coordinates.length; i++) {
        // geoJsonObj.coordinates[i][2] = 0;
      // }
    // }

    return geoJsonObj;
  }

  // load jason as input
  loadGeojson(data) {
    if (!this.GeoJSON) {
      this.GeoJSON = new GeoJSON();
    }

    var feature = this.GeoJSON.readFeature({"type":"Feature","geometry":data});
    this._feature = feature;
    //this.source.set(sources);
    if(this.style){
      this._feature.setStyle(this.style);
    }
    this.vectorLayer.getSource().addFeatures([feature]); // addFeatures
  }

  // activate funtion
  activate() {

  }

  deactivate() {
    if(this.map){
      this.map.getViewport().style.cursor = '';
      this.map.removeInteraction(this.draw);
      this.map.un('contextmenu', function(evt) { // unlisten
        });
      this.map.un('dblclick', function(evt) {
      });
      
    }

    //console.log("this._onFinished",this._onFinished)
    // if (this.draw) {
      // //this.draw.finishDrawing();
      // let that = this;
    // }

    if (this.source){
      //console.log("remove listen");
      this.source.un('addfeature',function(evt) {
        console.log("remove listen")
        })
    }
   
    return false;
  }


  clear() {
    this.deactivate();
        
    if (this.features){

      for(let i=0;i<this.features.length;i++){
        if (this.source && this.features[i]){
          this.source.removeFeature(this.features[i]);
          this.features[i].dispose();
          this.features[i] = undefined;
        }
      }
      this.features =[]
    }
    if(this.source && this._feature){
      try{
        this.source.removeFeature(this._feature);
        this._feature.dispose();
        this._feature = undefined;
      }
      catch(e){
        
      }
    }
  }
 

}

export default DrawOL;
